import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import moment from "moment"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Media,
  Button,
  Badge,
  Table,
} from "reactstrap"

import { exportComponentAsJPEG } from "react-component-export-image"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  getOrders,
  getOrderDetails,
  updateOrder,
  updatePayment,
} from "store/orders/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { CurrencySwitch } from "common/Currency"
import OrderItemTable from "./OrderItemTable"
import MetaTag from "components/Common/Meta-tag"
import { AvForm } from "availity-reactstrap-validation"
import { map } from "lodash"
import { LogoFull } from "assets/images/dealsToHome"

function OrderDetail() {
  const dispatch = useDispatch()
  const params = useParams()
  const orderDetailCompRef = useRef()

  const { orderDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
  }))

  useEffect(() => {
    dispatch(getOrders())
  }, [dispatch])

  useEffect(() => {
    dispatch(getOrderDetails(params.id))
  }, [dispatch])

  const currecny = CurrencySwitch()

  const orderStatus = [
    "Processing",
    // "Fulfilled",
    "Shipped",
    "Delivered",
    "Cancelled",
    // "Cancel Processing",
    "Returned",
    // "Return Processing",
  ]

  const handleOrderStatus = () => {
    if (orderDetails?.orderStatus === "Pending") {
      return [
        { status: "Accepted", id: 1, className: "success" },
        // { status: "Cancelled", id: 2, className: "danger" },
      ]
    }
    if (orderDetails?.orderStatus === "Accepted") {
      return [
        { status: "Processing", id: 1, className: "success" },
        // { status: "Cancelled", id: 2, className: "danger" },
      ]
    }
    if (orderDetails?.orderStatus === "Processing") {
      return [
        { status: "Shipped", id: 1, className: "success" },
        // { status: "Cancelled", id: 2, className: "danger" },
      ]
    }
    if (orderDetails?.orderStatus === "Shipped") {
      return [{ status: "Delivered", id: 1, className: "info" }]
    }
    // if (orderDetails?.orderStatus === "Delivered") {
    //   return [{ status: "Returned", id: 1, className: "secondary" }]
    // }
  }

  const handleOrderStatusClass = status => {
    switch (status) {
      case "Pending":
      case "Processing":
      case "Shipped":
        return "info"
      case "Accepted":
        return "success"
      case "Delivered":
        return "primary"
      case "Cancelled":
        return "danger"
      case "Returned":
        return "warning"
      default:
        break
    }
  }

  const handleValidSubmit = (e, v) => {
    e.preventDefault()

    const updateOrders = {
      orderId: orderDetails?._id,
      orderStatus: v.status,
    }
    dispatch(updateOrder(updateOrders))
  }

  const handlePaidFun = paid => {
    const updateOrders = {
      orderId: orderDetails?._id,
      isPaid: paid === "paid" ? true : false,
    }
    dispatch(updatePayment(updateOrders))
  }

  useEffect(() => {
    const updateOrders = {
      orderId: orderDetails?._id,
      isPaid: true,
    }

    if (
      orderDetails?.paymentType === "Online Payment" &&
      orderDetails?.isPaid === false
    ) {
      dispatch(updatePayment(updateOrders))
    }
  }, [dispatch, orderDetails?.paymentType])

  const [copy, setCopy] = useState(false)

  useEffect(() => {
    if (copy === true) {
      setTimeout(() => {
        setCopy(false)
      }, 3000)
    }
  }, [copy])

  const handleCopy = text => {
    navigator.clipboard.writeText(text)
    setCopy(true)
  }

  const address = orderDetails && orderDetails?.shippingInfo

  const [isPdf, setIsPdf] = useState(false)

  const handleDownload = () => {
    setIsPdf(true)
  }

  useEffect(() => {
    if (isPdf === true) {
      exportComponentAsJPEG(orderDetailCompRef)
      setIsPdf(false)
    }
  }, [isPdf])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Order Details" />
        <Container fluid>
          <Breadcrumbs title="Order" breadcrumbItem="Order Details" />
          {orderDetails?._id && (
            <div>
              <Row>
                <div className="col-12 col-xl-8">
                  <div ref={orderDetailCompRef}>
                    <Card>
                      <CardBody className="mt-2">
                        <div
                          className="mb-3 ms-auto"
                          style={{ height: "30px", width: "80px" }}
                        >
                          <img src={LogoFull} alt="" className="h-100 w-100" />
                        </div>
                        <div
                          className={`d-flex align-items-start justify-content-between mb-30 sm:flex-col`}
                        >
                          {" "}
                          <div className="text sm:mb-10">
                            <h5 className="mb-1" style={{ fontWeight: "bold" }}>
                              {address?.name}
                            </h5>
                            <Col sm="12" lg="8" className="mt-2">
                              <span className="d-flex align-items-baseline">
                                <i className="bx bx-map me-2"></i>
                                <p style={{ marginLeft: "2px" }}>
                                  {address?.address}
                                  {address?.addressLine1
                                    ? `${address?.addressLine1}${","}`
                                    : ""}{" "}
                                  {address?.addressLine2
                                    ? `${address?.addressLine2}${","}`
                                    : ""}{" "}
                                  {address?.street
                                    ? `${address?.street}${","}`
                                    : ""}{" "}
                                  {address?.landmark
                                    ? `${address?.landmark}${","}`
                                    : ""}{" "}
                                  {address?.area
                                    ? `${address?.area}${","}`
                                    : ""}{" "}
                                  <br />{" "}
                                  {address?.district
                                    ? `${address?.district}${","}`
                                    : ""}{" "}
                                  {address?.state
                                    ? `${address?.state}${","}`
                                    : ""}{" "}
                                  {address?.pinCode
                                    ? `${address?.pinCode}`
                                    : ""}
                                </p>
                              </span>
                            </Col>
                            {address?.email && (
                              <p className="mb-0">
                                <i className="bx bx-mail-send me-2"></i>{" "}
                                {address?.email}
                              </p>
                            )}
                            {address?.contactNumber && (
                              <h6
                                className="mb-0 mt-1 "
                                style={{ fontWeight: "600" }}
                              >
                                <i className="fas fa-phone me-2"></i>{" "}
                                {address?.contactNumber}
                              </h6>
                            )}
                          </div>
                          <div className="text-end">
                            <div className="text-muted">
                              <p className="mb-0">
                                Order ID :{" "}
                                <span
                                  className="text_deals_green"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {orderDetails?.orderId}
                                </span>
                              </p>
                              <span className="text-">
                                {moment(orderDetails?.createdAt).format(
                                  "DD MMMM YYYY"
                                )}
                                <br />
                                {moment(orderDetails?.createdAt).format("LT")}
                              </span>
                            </div>
                          </div>
                        </div>

                        <Label className="mt-5">
                          Order Items ({orderDetails?.orderItems?.length}){" "}
                        </Label>
                        <OrderItemTable currecny={currecny} isPdf={isPdf} />
                        <Row>
                          <Col>
                            <hr />
                            <Col
                              className=""
                              style={{
                                marginLeft: "auto",
                                width: "fit-content",
                              }}
                            >
                              <div className="table-responsive mt-4">
                                <Table className="table mb-0 myOrderTable ">
                                  <tbody>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Item Total
                                      </td>
                                      <td>:</td>
                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className="text-black  text-end">
                                          {currecny}{" "}
                                          {orderDetails?.itemsPrice?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Shipping
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-black text-end">
                                          {currecny}{" "}
                                          {orderDetails?.shippingPrice?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Discount
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-success text-end">
                                          {currecny}{" "}
                                          {orderDetails?.totalDiscount?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Tax
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-black text-end">
                                          {currecny}{" "}
                                          {orderDetails?.taxValue?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <h6
                                          style={{ fontWeight: "600" }}
                                          className="mb-0"
                                        >
                                          Gross Total
                                        </h6>
                                      </td>
                                      <td className="pb-0">
                                        <h6 style={{ fontWeight: "600" }}>:</h6>
                                      </td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <h6
                                          style={{ fontWeight: "600" }}
                                          className="text-end mb-0"
                                        >
                                          {currecny}{" "}
                                          {orderDetails?.totalPrice?.toFixed(2)}
                                        </h6>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="col-12 col-xl-4">
                  <Card>
                    <CardBody>
                      <Media body>
                        <div className="text-muted">
                          <p className="mb-3 pb-0">
                            Payment Type :{" "}
                            <Badge className="px-2 py-2 badge-soft-success ">
                              {orderDetails?.paymentType}
                            </Badge>
                          </p>
                          {orderDetails?.paymentType === "Cash on Delivery" ? (
                            <p className="mb-0 pb-0"></p>
                          ) : (
                            <p className="mb-0 pb-0">
                              Payment Id :{" "}
                              {orderDetails?.onlinePayment?.razorpay_payment_id}
                            </p>
                          )}
                        </div>
                        <div className="table-responsive mt-4">
                          <Table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                <th></th>
                                <th className="text-start">Status</th>
                                <th className="text-start"> Update</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Payment
                                </td>
                                <td
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {" "}
                                  <p className="mb-0 pb-0 d-flex align-items-center ">
                                    {orderDetails?.paymentType ===
                                      "Cash on Delivery" &&
                                    orderDetails?.isPaid === false ? (
                                      <Badge className="px-3 py-2 badge-soft-danger">
                                        Not Paid
                                      </Badge>
                                    ) : (
                                      <Badge className="px-3 py-2 badge-soft-success">
                                        Paid
                                      </Badge>
                                    )}
                                  </p>
                                </td>
                                <td className="text-start">
                                  {orderDetails?.paymentType ===
                                    "Cash on Delivery" &&
                                    !orderDetails?.isPaid && (
                                      <div className="">
                                        <Button
                                          type="submit"
                                          color="black"
                                          className={
                                            "hover-gray px-3 btn text-black"
                                          }
                                          style={{
                                            border: "1px solid whitesmoke",
                                            width: "fit-content",
                                          }}
                                          onClick={() => handlePaidFun("paid")}
                                        >
                                          Paid
                                        </Button>
                                      </div>
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Order{" "}
                                </td>
                                <td
                                  className="text-start"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <p className="mb-0 pb-0 d-flex align-items-center ">
                                    <Badge
                                      className={
                                        "px-3 py-2 badge-soft-" +
                                        handleOrderStatusClass(
                                          orderDetails?.orderStatus
                                        )
                                      }
                                    >
                                      {orderDetails?.orderStatus}
                                    </Badge>
                                  </p>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {handleOrderStatus()?.length >= 1 && (
                                    <div className="">
                                      <AvForm className="form-horizontal ">
                                        <div className="d-flex flex-wrap w-full align-items-center">
                                          <div className="d-flex">
                                            {map(
                                              handleOrderStatus(),
                                              (item, key) => (
                                                <Button
                                                  key={key}
                                                  type="submit"
                                                  color="black"
                                                  className={
                                                    "hover-gray btn mt-2 text-black"
                                                  }
                                                  style={{
                                                    border:
                                                      "1px solid whitesmoke",
                                                    width: "fit-content",
                                                  }}
                                                  onClick={e =>
                                                    handleValidSubmit(e, item)
                                                  }
                                                >
                                                  {item?.status}
                                                </Button>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </AvForm>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <p className="mb-0 pb-0" style={{ fontWeight: "bold" }}>
                        Delivery Details :
                      </p>
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <div className="d-flex flex-wrap align-items-center">
                          <Badge className="px-3 py-2 badge bg-info d-flex">
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              className="text-white font-size-12"
                              href={`https://maps.google.com/?q=${orderDetails?.userCoordinates[0]},${orderDetails?.userCoordinates[1]}`}
                            >
                              {" "}
                              View map
                            </a>
                          </Badge>
                          <Badge
                            className="px-3 py-2 mx-3 bg-info font-size-12 "
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCopy(
                                `https://maps.google.com/?q=${orderDetails?.userCoordinates[0]},${orderDetails?.userCoordinates[1]}`
                              )
                            }
                          >
                            <i
                              className="bx bx-copy"
                              style={{ cursor: "copy" }}
                              title="Copy url link"
                            ></i>
                            <span className="mx-1">Copy location</span>
                          </Badge>
                          <Badge className="px-4 py-2 mt-3  badge-soft-info font-size-14">
                            <i className="bx bx-timer mx-1 mb-0 "></i>
                            {orderDetails?.userDrivingDuration}
                          </Badge>

                          <Badge className="px-4 py-2 mx-2 mt-3 badge-soft-info font-size-14">
                            <i className="bx bx-run mx-1 "></i>
                            {orderDetails?.userDrivingDistance} km
                          </Badge>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-3">
                        <Badge
                          className="px-3 py-2 bg-success font-size-12 "
                          style={{ cursor: "pointer" }}
                          onClick={handleDownload}
                        >
                          <i
                            className="bx bxs-download"
                            style={{ cursor: "pointer" }}
                          ></i>{" "}
                          Download order
                        </Badge>
                        {copy === true && (
                          <p className="mb-0 pb-0 mt-2 mx-2 text-success">
                            URL copied!
                          </p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  order: PropTypes.object,
  match: PropTypes.any,
  onGetOrderDetail: PropTypes.func,
}

export default OrderDetail
