import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Button,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import { Link } from "react-router-dom"
import Switch from "react-switch"
import classnames from "classnames"

//actions
import { getMapPublicKey, getStoreDetails, updateStore } from "store/actions"
import { getUsers } from "store/users/actions"
import MetaTag from "components/Common/Meta-tag"
import MyGoogleMap from "components/Gmap/googleMap"

const UpdateStore = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { storeDetails, loading, createStoreError, mapKey } = useSelector(
    state => ({
      createStoreError: state.Stores.createStoreError,
      storeDetails: state.Stores.storeDetails,
      loading: state.Stores.loading,
      mapKey: state.Settings.map,
    })
  )

  console.log(storeDetails)

  const [markers, setMarkers] = useState([])

  const [isDirectDelivery, setIsDirectDelivery] = useState(
    storeDetails?.isDirectDelivery || false
  )
  const [isFreeDeliveryAvailable, setIsFreeDeliveryAvailable] = useState(
    storeDetails?.directDeliverySettings?.isFreeDeliveryAvailable
  )
  const [isNearByDeliveryAvailable, setIsNearByDeliveryAvailable] = useState(
    storeDetails?.directDeliverySettings?.isNearByDeliveryAvailable
  )

  const [activeTab, setactiveTab] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function handleValidSubmit(values) {
    const updateStoreData = {
      storeAdminDetails: values.storeAdminDetails,
      storeNo: values.storeNo,
      storeName: values.storeName,
      phone: values.phone,
      email: values.email,
      storeAddress: values.storeAddress,
      location: {
        type: "Point",
        coordinates: [
          values.location?.coordinates[0],
          values.location?.coordinates[1],
        ],
      },
      workingHours: values.workingHours,
      defaultShippingCharge: parseFloat(values.defaultShippingCharge),
      freeShippingThreshold: parseFloat(values.freeShippingThreshold),
      minDeliveryCharge: parseFloat(values.minDeliveryCharge),
      areaBasedShippingCharge: [],

      isDirectDelivery: isDirectDelivery,
      directDeliverySettings: {
        distanceUnit: values.distanceUnit,
        deliveryChargePerDistance: parseFloat(values.deliveryChargePerDistance),
        isFreeDeliveryAvailable: isFreeDeliveryAvailable,
        freeDeliveryDistanceThreshold: parseFloat(
          values.freeDeliveryDistanceThreshold
        ),
        freeDeliveryOrderValueThreshold: parseFloat(
          values.freeDeliveryOrderValueThreshold
        ),
        maxDeliveryArea: parseFloat(values.maxDeliveryArea),
        isNearByDeliveryAvailable: isNearByDeliveryAvailable,
        maxNearByDeliveryArea: parseFloat(values.maxNearByDeliveryArea),
        nearByFreeDeliveryOrderValueThreshold: parseFloat(
          values.nearByFreeDeliveryOrderValueThreshold
        ),
      },
    }

    dispatch(updateStore(updateStoreData, storeDetails?._id, history, ""))
  }

  // nearByFreeDeliveryOrderValueThreshold: 0

  useEffect(() => {
    dispatch(getStoreDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUsers(params?._id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  useEffect(() => {
    setIsDirectDelivery(storeDetails?.isDirectDelivery)
    setIsFreeDeliveryAvailable(
      storeDetails?.directDeliverySettings?.isFreeDeliveryAvailable
    )
    setIsNearByDeliveryAvailable(
      storeDetails?.directDeliverySettings?.isNearByDeliveryAvailable
    )

    setMarkers(current => [
      {
        lat: storeDetails?.location?.coordinates[0],
        lng: storeDetails?.location?.coordinates[1],
        time: new Date(),
      },
    ])
  }, [storeDetails])

  const MAP_PUBLIC_KEY = mapKey?.key

  return (
    <>
      <MetaTag title="Update Store" />

      <div className="page-content">
        <Breadcrumbs title="Stores" breadcrumbItem="Update Store" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
                className="sm:p-0"
              >
                <Col lg={12} md={12}>
                  {createStoreError && (
                    <Alert color="danger" value={createStoreError} />
                  )}

                  <Row>
                    <Card>
                      <CardBody>
                        <div className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    current: activeTab === 1,
                                  })}
                                  onClick={() => {
                                    setactiveTab(1)
                                  }}
                                  disabled={!(passedSteps || []).includes(1)}
                                >
                                  <span className="number">1.</span> Store Admin
                                  Details
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 2,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 2,
                                  })}
                                  onClick={() => {
                                    setactiveTab(2)
                                  }}
                                  disabled={!(passedSteps || []).includes(2)}
                                >
                                  <span className="number ms-2">02</span> Store
                                  Details
                                </NavLink>
                              </NavItem>
                              {/* <NavItem
                                className={classnames({
                                  current: activeTab === 3,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 3,
                                  })}
                                  onClick={() => {
                                    setactiveTab(3)
                                  }}
                                  disabled={!(passedSteps || []).includes(3)}
                                >
                                  <span className="number">03</span> Working
                                  Hours
                                </NavLink>
                              </NavItem> */}
                              <NavItem
                                className={classnames({
                                  current: activeTab === 3,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 3,
                                  })}
                                  onClick={() => {
                                    setactiveTab(3)
                                  }}
                                  disabled={!(passedSteps || []).includes(3)}
                                >
                                  <span className="number">03</span> Shipping
                                  Details
                                </NavLink>
                              </NavItem>
                            </ul>
                          </div>
                          <div className="content clearfix mt-4">
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.name"
                                        label="Name"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails?.name
                                        }
                                        readOnly
                                        errorMessage="Invalid Name"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.phone"
                                        label="Contact No."
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails?.phone
                                        }
                                        readOnly
                                        errorMessage="Invalid phone no."
                                        validate={{
                                          required: { value: true },
                                          maxLength: {
                                            value: 10,
                                            errorMessage:
                                              "phone number must be 10 digits",
                                          },
                                          minLength: {
                                            value: 10,
                                            errorMessage:
                                              "phone number must be 10 digits",
                                          },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                <AvField
                                    name="storeAdminDetails.email"
                                    label="Email"
                                    type="email"
                                    errorMessage="Invalid email"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                <AvField
                                    name="storeAdminDetails.password"
                                    label="Password"
                                    type="text"
                                    errorMessage="Invalid password"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row> */}

                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.addressLine1"
                                        label="Address line 1"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.addressLine1
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.addressLine2"
                                        label="Address line 2"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.addressLine2
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.landmark"
                                        label="Landmark"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.landmark
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.area"
                                        label="Area"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.area
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.district"
                                        label="District"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.district
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.state"
                                        label="State"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.state
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.country"
                                        label="Country"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.country
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.pinCode"
                                        label="Pin code"
                                        type="text"
                                        value={
                                          storeDetails?.storeAdminDetails
                                            ?.address?.pinCode
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={2}>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeName"
                                        label="Store Name"
                                        type="text"
                                        value={storeDetails?.storeName}
                                        errorMessage="Invalid store name"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="3">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeNo"
                                        label="Store Number"
                                        type="number"
                                        value={storeDetails?.storeNo}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="phone"
                                        label="Contact Number"
                                        type="text"
                                        value={storeDetails?.phone}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={storeDetails?.email}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.addressLine1"
                                        label="Address line 1"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress
                                            ?.addressLine1
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.addressLine2"
                                        label="Address line 2"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress
                                            ?.addressLine2
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.landmark"
                                        label="Landmark"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress?.landmark
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.area"
                                        label="Area"
                                        type="text"
                                        value={storeDetails?.storeAddress?.area}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.district"
                                        label="District"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress?.district
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.state"
                                        label="State"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress?.state
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.country"
                                        label="Country"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress?.country
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.pinCode"
                                        label="Pin code"
                                        type="text"
                                        value={
                                          storeDetails?.storeAddress?.pinCode
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Card>
                                  <CardBody className="">
                                    <Row>
                                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                                        <MyGoogleMap
                                          MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                                          setMarkers={setMarkers}
                                          markers={markers}
                                        />
                                      </Col>
                                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="col-12 mt-5 mx-5">
                                          <Label>
                                            <i className="bx bx-map"></i>{" "}
                                            Location( lat / lng )
                                          </Label>
                                        </div>
                                        <div className="col-6 mb-3 mt-2 mx-5">
                                          <AvField
                                            name="location.coordinates[0]"
                                            placeholder="Latitude"
                                            type="text"
                                            id="#geolocation"
                                            errorMessage="Invalid latitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={markers[0]?.lat}
                                          />
                                        </div>
                                        <div className="col-6 mb-3 mt-3 mx-5">
                                          <AvField
                                            name="location.coordinates[1]"
                                            placeholder="Longitude"
                                            type="text"
                                            errorMessage="Invalid longitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={markers[0]?.lng}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </TabPane>
                              {/* <TabPane tabId={3}>
                                <div></div>
                              </TabPane> */}
                              <TabPane tabId={3}>
                                <div>
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="defaultShippingCharge"
                                          label="Default Shipping Charge"
                                          value={
                                            storeDetails?.defaultShippingCharge
                                          }
                                          type="number"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="freeShippingThreshold"
                                          label="Free Shipping Threshold"
                                          value={
                                            storeDetails?.freeShippingThreshold
                                          }
                                          type="number"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="minDeliveryCharge"
                                          label="min Delivery Charge"
                                          value={
                                            storeDetails?.directDeliverySettings
                                              ?.minDeliveryCharge
                                          }
                                          type="number"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-6 mb-3">
                                      <Label check for="checkbox">
                                        Is Direct Delivery :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2 mx-3"
                                        onColor="#626ed4"
                                        onChange={v => {
                                          setIsDirectDelivery(v)
                                        }}
                                        checked={
                                          isDirectDelivery
                                            ? isDirectDelivery
                                            : false
                                        }
                                      />
                                    </div>
                                  </Col>
                                  {isDirectDelivery && (
                                    <>
                                      <Row>
                                        <Col lg="3">
                                          <div className="mb-3">
                                            <AvField
                                              name="distanceUnit"
                                              label="Distance Unit"
                                              value={
                                                storeDetails
                                                  ?.directDeliverySettings
                                                  ?.distanceUnit
                                              }
                                              type="text"
                                            />
                                          </div>
                                        </Col>
                                        <Col lg="3">
                                          <div className="mb-3">
                                            <AvField
                                              name="deliveryChargePerDistance"
                                              label="Delivery Charge Per Distance"
                                              value={
                                                storeDetails
                                                  ?.directDeliverySettings
                                                  ?.deliveryChargePerDistance
                                              }
                                              type="number"
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mt-1">
                                          <Label check for="checkbox">
                                            Is Free Delivery Available :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsFreeDeliveryAvailable(v)
                                            }}
                                            checked={
                                              isFreeDeliveryAvailable
                                                ? isFreeDeliveryAvailable
                                                : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                      {isFreeDeliveryAvailable && (
                                        <Row>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="freeDeliveryDistanceThreshold"
                                                label="Free Delivery Distance Threshold"
                                                value={
                                                  storeDetails
                                                    ?.directDeliverySettings
                                                    ?.freeDeliveryDistanceThreshold
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="freeDeliveryOrderValueThreshold"
                                                label="Free Delivery Order Value Threshold"
                                                value={
                                                  storeDetails
                                                    ?.directDeliverySettings
                                                    ?.freeDeliveryOrderValueThreshold
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="maxDeliveryArea"
                                                label="Max Delivery Area"
                                                value={
                                                  storeDetails
                                                    ?.directDeliverySettings
                                                    ?.maxDeliveryArea
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mt-1">
                                          <Label check for="checkbox">
                                            Is Near By Delivery Available :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsNearByDeliveryAvailable(v)
                                            }}
                                            checked={
                                              isNearByDeliveryAvailable
                                                ? isNearByDeliveryAvailable
                                                : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                      {isNearByDeliveryAvailable && (
                                        <Row>
                                          <Col lg="4">
                                            <div className="mb-3">
                                              <AvField
                                                name="maxNearByDeliveryArea"
                                                label="Max Near By Delivery Area"
                                                value={
                                                  storeDetails
                                                    ?.directDeliverySettings
                                                    ?.maxNearByDeliveryArea
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg="4">
                                            <div className="mb-3">
                                              <AvField
                                                name="nearByFreeDeliveryOrderValueThreshold"
                                                label="Near By Free Delivery Order Value Threshold"
                                                value={
                                                  storeDetails
                                                    ?.directDeliverySettings
                                                    ?.nearByFreeDeliveryOrderValueThreshold
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  )}
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                >
                                  Previous
                                </Link>
                              </li>
                              <li
                                className={
                                  activeTab === 3 ? "next disabled" : "next"
                                }
                              >
                                {" "}
                                {activeTab !== 3 && (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    Next
                                  </Link>
                                )}
                              </li>
                              {activeTab === 3 && (
                                <li>
                                  <Button
                                    type="submit"
                                    color="success"
                                    className="w-sm btn-block"
                                  >
                                    {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )}{" "}
                                    Update Store
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </AvForm>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateStore

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}
