import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  GET_GMAP_PKEY_FAIL,
  GET_GMAP_PKEY_SUCCESS,
  GET_GMAP_PKEY,
  SEARCH_LOCATION_FAIL,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION,
} from "./actionTypes"

const INIT_STATE = {
  settings: {},
  privacy: {},
  error: {},
  map: "",
  mapLoading: false,
  places: {
    places: [],
  },
  placesLoading: false,
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        privacy: action.payload.legal.privacyPolicy,
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
      }

    case UPDATE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_GMAP_PKEY:
      return {
        ...state,
        mapLoading: true,
      }

    case GET_GMAP_PKEY_SUCCESS:
      return {
        ...state,
        map: action.payload,
        mapLoading: false,
      }

    case GET_GMAP_PKEY_FAIL:
      return {
        ...state,
        error: action.payload,
        mapLoading: false,
      }

    case SEARCH_LOCATION:
      return {
        ...state,
        placesLoading: true,
      }

    case SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        places: action.payload,
        placesLoading: false,
      }

    case SEARCH_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        placesLoading: false,
      }

    default:
      return state
  }
}

export default Settings
