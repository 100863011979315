import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
  GET_APP_REVIEWS,
  GET_APP_REVIEWS_FAIL,
  GET_APP_REVIEWS_SUCCESS,
  UPDATE_APP_REVIEW,
  UPDATE_APP_REVIEW_SUCCESS,
  UPDATE_APP_REVIEW_FAIL,
  DELETE_APP_REVIEW,
  DELETE_APP_REVIEW_SUCCESS,
  DELETE_APP_REVIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userCarts: [],
  userCartDetails: {},
  userFavouriteDetails: {},
  userDetails: {},
  userVarients: {},
  error: {},
  loading: false,
  createShopProductError: null,
  appReviews: {
    publish: {},
    draft: {},
  },
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
    case GET_USER_CARTS:
    case GET_USER_DETAILS:
    case GET_USER_CART_DETAILS:
    case GET_USER_FAVOURITE_DETAILS:
    // case UPDATE_USER:
    case DELETE_USER:
    case DELETE_USER_CART:
    case DELETE_USER_FAVOURITE:
    case GET_APP_REVIEWS:
      return {
        ...state,
        loading: true,
      }

    case GET_APP_REVIEWS_SUCCESS:
      return {
        ...state,
        appReviews: {
          publish: action.payload?.sort === "PUBLISHED" ? action.payload : {},
          draft: action.payload?.sort === "DRAFT" ? action.payload : {},
        },
        loading: false,
      }

    case GET_APP_REVIEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_APP_REVIEW:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_APP_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_APP_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_APP_REVIEW:
      return {
        ...state,
        loading: true,
      }

    case DELETE_APP_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_APP_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userVarients: action.payload.userVarients,
        loading: false,
      }

    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user =>
            user._id.toString() === action.payload._id.toString()
              ? { ...user, isPrime: action.payload.isPrime }
              : user
          ),
        },
        loading: false,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: {
          users: state?.users?.users.filter(
            user => user._id !== action.payload.id
          ),
        },
        loading: false,
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_CARTS_SUCCESS:
      return {
        ...state,
        userCarts: action.payload,
        loading: false,
      }

    case GET_USER_CARTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_CART_DETAILS_SUCCESS:
      return {
        ...state,
        userCartDetails: action.payload,
      }

    case GET_USER_CART_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_USER_CART_SUCCESS:
      return {
        ...state,
        userCarts: state.userCarts.filter(
          userCart => userCart._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case DELETE_USER_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_CART_DETAILS_SUCCESS:
      return {
        ...state,
        userCartDetails: action.payload,
      }

    case GET_USER_CART_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_USER_CART_SUCCESS:
      return {
        ...state,
        userCarts: state.userCarts.filter(
          userCart => userCart._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case DELETE_USER_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_FAVOURITE_DETAILS_SUCCESS:
      return {
        ...state,
        userFavouriteDetails: action.payload,
      }

    case GET_USER_FAVOURITE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_USER_FAVOURITE_SUCCESS:
      return {
        ...state,
        userFavourites: state.userFavourites.filter(
          userFavourite =>
            userFavourite._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case DELETE_USER_FAVOURITE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Users
