import MetaTag from "components/Common/Meta-tag"
import React from "react"

import ShopDetail from "./Details"

function ShopDetails() {
  return (
    <>
      <MetaTag title={"Shop"} />

      <ShopDetail />
    </>
  )
}

export default ShopDetails
