import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_CARTS,
  GET_USER_CART_DETAILS,
  DELETE_USER_CART,
  GET_USER_FAVOURITE_DETAILS,
  GET_APP_REVIEWS,
  UPDATE_APP_REVIEW,
  DELETE_APP_REVIEW,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserCartsFail,
  getUserCartDetailsSuccess,
  getUserCartDetailsFail,
  deleteUserCartSuccess,
  deleteUserCartFail,
  getUserFavouriteDetailsSuccess,
  getUserFavouriteDetailsFail,
  getAppReviewSuccess,
  getAppReviewFail,
  updateAppReviewSuccess,
  updateAppReviewFail,
  deleteAppReviewSuccess,
  deleteAppReviewFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// function getZestonProductsAPi({ searchText, page }) {
//   if (searchText) {
//     return get(`/zestonProduct/all?search=${searchText && searchText}`)
//   } else {
//     return get(`/zestonProduct/all?page=${page ? page : 1}&limit=10`)
//   }
// }

function getUsersAPi({ page, sort, searchText }) {
  if (searchText) {
    return get(`/user/admin/custom/${sort ? sort : "all"}?search=${searchText}`)
  }
  return get(
    `/user/admin/custom/${sort ? sort : "all"}?page=${page ? page : 1}&limit=10`
  )
}

function getCartsAPi() {
  return get("/cart/admin/all")
}

const getUserDetailsAPi = userId => {
  return get(`/user/admin/${userId}`)
}

const updateUserApi = ({ user, userId }) => {
  return ApiPut(`/user/admin/${userId}`, user)
}

const deleteUserApi = userId => {
  return del(`/user/admin/${userId}`)
}
const deleteUserCartApi = userId => {
  return del(`/cart/admin/${userId}`)
}

const getUserCartDetailsAPi = userId => {
  return get(`/cart/admin/user/${userId}`)
}

const getUserFavouriteDetailsAPi = userId => {
  return get(`/favourite/admin/userId/${userId}`)
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersAPi, payload)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
    yield put(getUserCartsFail(error))
  }
}

function getAppReviewApi({ page, sort, userId }) {
  return get(
    `/app-review/admin/all?filterValue=${sort ? sort : "DRAFT"}&page=${
      page ? page : 1
    }&limit=10&user=${userId}`
  )
}

function* getAppReviews({ payload }) {
  try {
    const response = yield call(getAppReviewApi, payload)
    yield put(getAppReviewSuccess({ ...response, sort: payload?.sort }))
  } catch (error) {
    yield put(getAppReviewFail(error))
  }
}

function updateAppReviewApi({ id, status }) {
  return ApiPut(`/app-review/admin/${id}`, { is_active: status })
}

function* updateAppReview({ payload }) {
  try {
    const response = yield call(updateAppReviewApi, payload)
    if (response?._id) {
      payload?.apiCall()
      yield put(updateAppReviewSuccess(response))
    }
  } catch (error) {
    yield put(updateAppReviewFail(error))
  }
}

function deleteAppReviewApi({ id }) {
  return del(`/app-review/admin/${id}`)
}

function* deleteAppReview({ payload }) {
  try {
    const response = yield call(deleteAppReviewApi, payload)
    if (response?._id) {
      payload?.apiCall()
      yield put(deleteAppReviewSuccess(response))
    }
  } catch (error) {
    yield put(deleteAppReviewFail(error))
  }
}

function* fetchUserDetail({ payload: userId }) {
  try {
    const response = yield call(getUserDetailsAPi, userId)
    yield put(getUserDetailsSuccess(response))
  } catch (error) {
    yield put(getUserDetailsFail(error))
  }
}

function* fetchUserCartDetail({ payload: userId }) {
  try {
    const response = yield call(getUserCartDetailsAPi, userId)
    yield put(getUserCartDetailsSuccess(response))
  } catch (error) {
    yield put(getUserCartDetailsFail(error))
  }
}

function* fetchUserFavouriteDetail({ payload: userId }) {
  try {
    const response = yield call(getUserFavouriteDetailsAPi, userId)
    yield put(getUserFavouriteDetailsSuccess(response))
  } catch (error) {
    yield put(getUserFavouriteDetailsFail(error))
  }
}

function* onUpdateUser({ payload }) {
  try {
    const response = yield call(updateUserApi, payload)
    yield put(updateUserSuccess(response))

    Notification({
      type: "success",
      message: "User Updated Successfully!",
      title: "",
    })
    if (payload.history) {
      payload.history.goBack()
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { userId } }) {
  try {
    const response = yield call(deleteUserApi, userId)
    yield put(deleteUserSuccess(response, userId))
    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onDeleteUserCart({ payload: { userId } }) {
  try {
    const cartResponse = yield call(deleteUserCartApi, userId)
    yield put(deleteUserCartSuccess(cartResponse))

    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
    // history.goBack()
  } catch (error) {
    yield put(deleteUserCartFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_CARTS, fetchUsers)
  yield takeEvery(GET_USER_DETAILS, fetchUserDetail)
  yield takeEvery(GET_USER_CART_DETAILS, fetchUserCartDetail)
  yield takeEvery(GET_USER_FAVOURITE_DETAILS, fetchUserFavouriteDetail)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(DELETE_USER_CART, onDeleteUserCart)
  yield takeEvery(GET_APP_REVIEWS, getAppReviews)
  yield takeEvery(UPDATE_APP_REVIEW, updateAppReview)
  yield takeEvery(DELETE_APP_REVIEW, deleteAppReview)
}

export default userSaga
