import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Maps
import Leaflet from "leaflet"
import { Map, TileLayer, Marker } from "react-leaflet"
import "leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
})

import classnames from "classnames"
import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  deleteShop,
  updateShop,
  getShopDetails,
  changeShopStatus,
} from "store/shops/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import MetaTag from "components/Common/Meta-tag"
// import ShopProductDetails from ".";

const ShopDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [activeTab, setActiveTab] = useState("1")

  const ApproveList = {
    operation: "APPROVE",
  }

  const ActiveList = {
    operation: "ACTIVE",
  }

  const { shopDetails, shopVarients, loading } = useSelector(state => ({
    shopDetails: state.Shops.shopDetails,
    shopVarients: state.Shops.shopVarients,
    loading: state.Shops.loading,
  }))

  const handleDelete = () => {
    dispatch(deleteShop(shopDetails?._id, history))
  }

  // const handleApprove = (values) => {
  //   dispatch(updateShop(values, history))
  // }

  useEffect(() => {
    dispatch(getShopDetails(params.id))
  }, [dispatch])

  //Approve
  const handleSwitchChangeApprove = values => {
    const updateApproveData = {
      shopId: shopDetails?._id,
      operation: ApproveList.operation,
    }
    dispatch(changeShopStatus(updateApproveData, shopDetails?._id))
  }

  //Ban/unban
  const handleSwitchChangeActive = values => {
    const updateActiveData = {
      shopId: shopDetails?._id,
      operation: ActiveList.operation,
    }
    dispatch(changeShopStatus(updateActiveData, shopDetails?._id))
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id)
    expandImg.src = img
  }

  //Map
  const mapDetail = {
    lat: shopDetails?.location?.coordinates[0], //change lattitude
    lng: shopDetails?.location?.coordinates[1], //change logitude
    zoom: 13, //change zoom distance
  }
  const position = [mapDetail.lat, mapDetail.lng]

  const handleStatus = () => {
    if (shopDetails?.isApproved == false) {
      return {
        status: "Approve",
        className: "primary",
        icon: "fas fa-check me-2",
      }
    }
    if (shopDetails?.isActive == true) {
      return {
        status: "ban",
        className: "warning",
        icon: "fas fa-user-slash me-2",
      }
    } else if (shopDetails?.isActive == false) {
      return {
        status: "unban",
        className: "success",
        icon: "bx bx-user me-2",
      }
    }
  }

  const handleClick = () => {
    if (handleStatus().status == "Approve") {
      return handleSwitchChangeApprove(ApproveList?.operation)
    }
    if (handleStatus().status == "ban") {
      return handleSwitchChangeActive(ActiveList?.operation)
    } else if (handleStatus().status == "unban") {
      return handleSwitchChangeActive(ActiveList?.operation)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title={"Shop Details "} />

        <Container fluid>
          <Breadcrumbs title="Shop" breadcrumbItem="Shop Details" />
          {!isEmpty(shopDetails) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <div className="">
                          {/* <h5>  {`${shopDetails?.shopNo} -`} {shopDetails?.shopName}</h5> */}
                          <div
                            className="col-12 d-flex"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Link to={`/shop/update/${shopDetails?._id}`}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                              >
                                <i className="bx bx-edit me-2" /> Update
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              color="danger"
                              className="ms-1 btn mt-2"
                              onClick={() => {
                                handleDelete(shopDetails?._id)
                              }}
                            >
                              <i className="bx bx-trash me-2" />
                              Delete
                            </Button>

                            <Button
                              type="button"
                              color={handleStatus()?.className}
                              className="ms-1 btn mt-2"
                              onClick={handleClick}
                            >
                              <i className={handleStatus()?.icon} />
                              {handleStatus()?.status}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>
                        <Col md="12">
                          <div className="mt-0">
                            <h5 className="mb-2">
                              {" "}
                              {`${shopDetails?.shopNo} -`}{" "}
                              {shopDetails?.shopName}
                            </h5>
                            <hr />
                            <div>
                              <i className="bx bx-map me-2" />
                              <Label>Shop Address :</Label>
                              <Col className="mx-4">
                                {shopDetails?.shopAddress?.addressLine1}, <br />
                                {shopDetails?.shopAddress?.addressLine2}, <br />
                                {shopDetails?.shopAddress?.landmark},{" "}
                                {shopDetails?.shopAddress?.area}, <br />
                                {shopDetails?.shopAddress?.district},{" "}
                                {shopDetails?.shopAddress?.state}, <br />
                                {shopDetails?.shopAddress?.pinCode}
                              </Col>
                            </div>
                          </div>

                          <Col className="mt-2">
                            <div>
                              <i className="fas fa-phone me-2" />
                              {shopDetails?.contactNumber}
                            </div>
                            <div className="mt-2">
                              <i className="fas fa-history me-2" />
                              {shopDetails?.workingTime}
                            </div>
                          </Col>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="6">
                    <div className="product-detai-imgs">
                      <Card>
                        <CardBody>
                          <Col md="12">
                            <div className="mt-0">
                              <h5 className="mb-2">
                                {" "}
                                {`${shopDetails?.dealerNo} -`}{" "}
                                {shopDetails?.dealerDetails?.name}
                              </h5>
                              <hr />
                              <div>
                                <i className="bx bx-map me-2" />
                                <Label>Dealer Address :</Label>

                                <Col className="mx-4">
                                  {
                                    shopDetails?.dealerDetails?.address
                                      ?.addressLine1
                                  }
                                  , <br />
                                  {
                                    shopDetails?.dealerDetails?.address
                                      ?.addressLine2
                                  }
                                  , <br />
                                  {
                                    shopDetails?.dealerDetails?.address
                                      ?.landmark
                                  }
                                  , {shopDetails?.dealerDetails?.address?.area},{" "}
                                  <br />
                                  {
                                    shopDetails?.dealerDetails?.address
                                      ?.district
                                  }
                                  , {shopDetails?.dealerDetails?.address?.state}
                                  , <br />
                                  {shopDetails?.dealerDetails?.address?.pinCode}
                                </Col>
                              </div>
                            </div>

                            <Col className="mt-2">
                              <div>
                                <i className="fas fa-phone me-2" />
                                {shopDetails?.dealerDetails?.contactNumber}
                              </div>
                              <div className="mt-2 text-white">
                                <i className="fas fa-history me-2" />
                                {shopDetails?.workingTime}
                              </div>
                            </Col>
                          </Col>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <Map
                          center={position}
                          zoom={mapDetail.zoom}
                          style={{ height: "400px" }}
                        >
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={position}></Marker>
                        </Map>
                      </Col>
                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 px-5 pt-5">
                        <Card>
                          <CardBody>
                            <Label>
                              <i className="bx bx-map" /> Location:{" "}
                            </Label>
                            <div>
                              <h5>
                                Latitude:{" "}
                                {shopDetails?.location?.coordinates[0]}
                              </h5>
                            </div>
                            <div className="d-flex brbtm">
                              <h5>
                                Longitude:{" "}
                                {shopDetails?.location?.coordinates[1]}
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ShopDetail.propTypes = {
  shop: PropTypes.object,
  match: PropTypes.any,
  onGetShopDetail: PropTypes.func,
}

export default ShopDetail
