import React from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import PropTypes from "prop-types"
import classNames from "classnames"
import { LanguageSwitch } from "common/LanguageSwitch"

function LanguageTab({ activeTab, setActiveTab }) {
  return (
    <Nav
      pills
      className="bg-light rounded mb-4"
      role="tablist"
      style={{ width: "fit-content" }}
    >
      <NavItem>
        <NavLink
          className={classNames(activeTab === "1" && "bg-info", {
            active: activeTab === "1",
          })}
          onClick={() => {
            setActiveTab("1")
          }}
          style={{ border: "1px solid #d0d6db" }}
        >
          {LanguageSwitch()?.lang1}
        </NavLink>
      </NavItem>
      <NavItem style={{ background: "#fff" }}>
        <NavLink
          className={classNames(activeTab === "2" && "bg-info", {
            active: activeTab === "2",
          })}
          onClick={() => {
            setActiveTab("2")
          }}
          style={{
            border: "1px solid #d0d6db",
            marginLeft: "0.5rem",
          }}
        >
          {LanguageSwitch()?.lang2 ?? "العربية"}
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default LanguageTab

LanguageTab.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
}
