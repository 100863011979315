import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getShopProductDetails, updateShopProduct } from "store/actions"
import { getShops } from "store/shops/actions"
import MetaTag from "components/Common/Meta-tag"

const UpdateShopProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { shopProductDetails, loading, createShopProductError } = useSelector(
    state => ({
      createShopProductError: state.ShopProducts.createShopProductError,
      shopProductDetails: state.ShopProducts.shopProductDetails,
      loading: state.ShopProducts.loading,
    })
  )

  const { shops, shopLoading } = useSelector(state => ({
    shops: state.Shops?.shops?.shops,
    shopLoading: state.Shops.shops?.loading,
  }))

  const [imgRows, setImgRows] = useState([{ id: 1 }])
  const [sizes, setSizes] = useState(shopProductDetails?.sizes)
  const [specs, setSpecs] = useState(shopProductDetails?.zestonProduct?.specs)

  const onAddImgRow = () => {
    const modifiedRows = [...imgRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setImgRows(modifiedRows)
  }

  const onDeleteImgRow = id => {
    if (id !== 1) {
      var modifiedRows = [...imgRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setImgRows(modifiedRows)
    }
  }

  function handleValidSubmit(values) {
    const updateProduct = {
      customID: values.customID,
      shop: values.shop._id,
      zestonProduct: values.zestonProduct._id,
      isFeatured: values.isFeatured,
      sizes: sizes,
    }

    dispatch(updateShopProduct(updateProduct, shopProductDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getShopProductDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getShops())
  }, [dispatch])

  return (
    <>
      <MetaTag title="Update Shop Product" />

      <div className="page-content">
        <Breadcrumbs
          title="Shop Products"
          breadcrumbItem="Update Shop Product"
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12} md={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {/* Fill this form */}
                    </CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {createShopProductError && (
                        <Alert color="danger">{createShopProductError}</Alert>
                      )}
                      <Row>
                        <Col className="col-12 d-flex flex-wrap">
                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              readOnly
                              name="name"
                              label="Product Name"
                              type="text"
                              errorMessage="Invalid product name"
                              validate={{
                                required: { value: true },
                              }}
                              value={
                                shopProductDetails?.zestonProduct?.name || ""
                              }
                            />
                            <AvField
                              readOnly
                              name="zestonProduct._id"
                              type="text"
                              errorMessage="Invalid product name"
                              validate={{
                                required: { value: true },
                              }}
                              value={
                                shopProductDetails?.zestonProduct?._id || ""
                              }
                            />
                          </div>

                          <div className="col-2 mb-3 mx-3">
                            <AvField
                              name="customID"
                              label="Custom ID"
                              type="text"
                              value={shopProductDetails?.customID || ""}
                            />

                            <AvField
                              name="shop._id"
                              type="text"
                              readOnly
                              value={shopProductDetails?.shop?._id || ""}
                            />
                          </div>

                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              readOnly
                              name="category"
                              label="Category"
                              type="text"
                              value={
                                shopProductDetails?.zestonProduct?.category
                                  ?.name || ""
                              }
                            />
                          </div>

                          <div className="col-12 mb-3 mx-3">
                            <AvGroup check>
                              <AvInput
                                type="checkbox"
                                name="isFeatured"
                                checked={shopProductDetails?.isFeatured}
                              />
                              <Label check for="checkbox">
                                {" "}
                                Featured Product
                              </Label>
                            </AvGroup>
                          </div>

                          <div className="col-12 mb-4 px-3">
                            <AvField
                              readOnly
                              name="description"
                              label="Description"
                              type="textarea"
                              // errorMessage="Invalid description"
                              rows="7"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              value={
                                shopProductDetails?.zestonProduct
                                  ?.description || ""
                              }
                            />
                          </div>

                          <div className="col-3 mb-3 mx-3">
                            <Label htmlFor="lblprice" className="form-label">
                              Color
                            </Label>
                            <span>
                              <a className="btn clrbtn mx-2 my-2">
                                <i
                                  className="bx bx-wine clrbbl"
                                  style={{
                                    color: `${shopProductDetails?.zestonProduct?.color?.value}`,
                                  }}
                                />
                                {shopProductDetails?.zestonProduct?.color?.name}
                              </a>
                            </span>
                          </div>

                          {/* specs */}
                          <div className="col-12 mb-5 px-3">
                            <Row className="mb-3 ">
                              <Col md="2">
                                <Label htmlFor="lbltype" className="form-label">
                                  Specifications
                                </Label>
                              </Col>
                            </Row>

                            <Col md="12" className="mx-3">
                              {specs?.map((s, index) => {
                                return (
                                  <div key={index}>
                                    <Row>
                                      <Col md="4">
                                        <AvField
                                          readOnly
                                          name="title"
                                          type="text"
                                          value={s.title}
                                          placeholder="Title"
                                          onChange={e => {
                                            const title = e.target.value
                                            setSpecs(currentSpec =>
                                              currentSpec.map(x =>
                                                x === s ? { ...x, title } : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col md="4">
                                        <AvField
                                          readOnly
                                          name="title"
                                          type="text"
                                          value={s.details}
                                          placeholder="Details"
                                          onChange={e => {
                                            const details = e.target.value
                                            setSpecs(currentPriceType =>
                                              currentPriceType.map(x =>
                                                x === s ? { ...x, details } : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      {/* <Col >
                                        <button
                                          className="mx-2 btn"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setSpecs((currentSpec) =>
                                              currentSpec.filter((x) => x !== s)
                                            );F
                                          }}
                                        >
                                        <i className="bx bx-trash" style={{ fontSize: "20px" }}></i>
                                        </button>
                                      </Col> */}
                                    </Row>
                                  </div>
                                )
                              })}
                            </Col>
                          </div>

                          {/* sizes */}
                          <div className="col-12 mb-3 px-3">
                            <Row className="mb-3">
                              <Col md="2">
                                <Label htmlFor="lbltype" className="form-label">
                                  Sizes
                                </Label>
                              </Col>
                              <Col md="12" className="mx-5 mb-3">
                                <Row>
                                  <Col
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Size
                                  </Col>
                                  <Col
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Zeston Price
                                  </Col>
                                  <Col
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Retail Price
                                  </Col>
                                  <Col
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Stock
                                  </Col>
                                  <Col
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                    }}
                                  ></Col>
                                </Row>
                              </Col>
                            </Row>

                            <Col md="12" className="mx-3">
                              {sizes?.map((s, index) => {
                                return (
                                  <div key={index} className="mb-3">
                                    <Row>
                                      <Col>
                                        <AvField
                                          readOnly
                                          name="value"
                                          type="text"
                                          value={s.value}
                                          placeholder="Value"
                                          onChange={e => {
                                            const value = e.target.value
                                            setSizes(currentSize =>
                                              currentSize.map(x =>
                                                x === s ? { ...x, value } : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col>
                                        <AvField
                                          readOnly
                                          name="zestonPrice"
                                          type="number"
                                          value={s.zestonPrice}
                                          placeholder="Zeston Price"
                                          onChange={e => {
                                            const zestonPrice = e.target.value
                                            setSizes(currentSize =>
                                              currentSize.map(x =>
                                                x === s
                                                  ? { ...x, zestonPrice }
                                                  : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col>
                                        <AvField
                                          name="retailPrice"
                                          type="number"
                                          value={s.retailPrice}
                                          placeholder="Retail Price"
                                          onChange={e => {
                                            const retailPrice = e.target.value
                                            setSizes(currentSize =>
                                              currentSize.map(x =>
                                                x === s
                                                  ? { ...x, retailPrice }
                                                  : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col>
                                        <AvField
                                          name="stock"
                                          type="number"
                                          value={s.stock}
                                          placeholder="Stock"
                                          onChange={e => {
                                            const stock = e.target.value
                                            setSizes(currentSize =>
                                              currentSize.map(x =>
                                                x === s ? { ...x, stock } : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col>
                                        <button
                                          className="mx-2   btn"
                                          onClick={e => {
                                            e.preventDefault()
                                            setSizes(currentSpec =>
                                              currentSpec.filter(x => x !== s)
                                            )
                                          }}
                                        >
                                          <i
                                            className="bx bx-trash"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              })}
                            </Col>
                          </div>

                          {/* <div className="col-12 mx-3 mt-3">
                            <Label htmlFor="lbltype" className="form-label">Images</Label>
                          </div>
                          {(imgRows || []).map((imgRow, key) => (
                            <Fragment key={key}>
                              <div >
                              </div>
                              <div className="col-12 d-flex">
                                <div className="col-4">
                                  <div className="input-group mx-3 my-2">
                                    <Input type="file" className="form-control" id="inputGroupFile02" onChange={()=> {onChangeImageHandler()}} />
                                  </div>

                                </div>
                                <div className="col-6">
                                  <button
                                    className="mx-3 btn"
                                    type="button"
                                    value="Add"
                                    onClick={() => onAddImgRow(imgRow.id)}
                                  ><i className="mt-2 bx bx-plus" style={{ fontSize: "20px" }}></i></button>

                                  <button
                                    className="mx-2 btn"
                                    type="button"
                                    value="Delete"
                                    onClick={() => onDeleteImgRow(imgRow.id)}
                                  ><i className="mt-2 bx bx-trash" style={{ fontSize: "20px" }}></i></button>
                                </div>
                              </div>
                            </Fragment>
                          ))} */}

                          {/* <div className="mx-3">
                                <Label htmlFor="lblprice" className="form-label">Price</Label>
                                  <Input id="lblprice" />
                                </div> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                {/* <PreviewCard /> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateShopProduct
