import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Badge, Button, FormGroup } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import Select from "react-select"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

import "../../assets/scss/datatables.scss"

import {
  deleteAppReview,
  getAppReview,
  getUsers,
  updateAppReview,
} from "store/actions"

const AppReviewTable = () => {
  const dispatch = useDispatch()

  const { appReviews, loading, users } = useSelector(state => ({
    appReviews: state.Users.appReviews,
    loading: state.Users.loading,
    users: state.Users.users?.users,
  }))

  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("PUBLISHED")

  const [userSearchText, setUserSearchText] = useState("")
  const [selectedUser, setSelectedUser] = useState("All User")
  const [finalUserId, setFinalUserId] = useState()

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleEnters = textEntered => {
    debounceUserSearch(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedUser(event.label)
    setFinalUserId(event.id)
  }

  useEffect(() => {
    dispatch(getUsers(page, "all", userSearchText))
  }, [dispatch, page, userSearchText])

  const storeOptions = [
    {
      label: "All Users",
      id: "",
    },
    {
      options: users?.map((result, index) => ({
        key: index,
        label: result?.name || result?.phone,
        id: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getAppReview(1, sort, finalUserId))
  }, [dispatch, sort, finalUserId])

  const total =
    sort === "PUBLISHED" ? appReviews?.publish?.total : appReviews?.draft?.total
  const appReviewsList =
    sort === "PUBLISHED"
      ? appReviews?.publish?.appReviews
      : appReviews?.draft?.appReviews

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "sl",
      filterText: "Sl no.",
      text: "Sl no.",
      sort: true,
    },
    {
      dataField: "user.name",
      filterText: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "message",
      filterText: "Message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "rating",
      filterText: "Rating",
      text: "Rating",
      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const allListApiCall = () => dispatch(getAppReview(1, sort))

  const handleChangeStatus = (id, is_active) => {
    dispatch(updateAppReview(id, is_active, allListApiCall))
  }

  const handleHardDelete = id => {
    dispatch(deleteAppReview(id, allListApiCall))
  }

  //all
  const allTableData = map(appReviewsList, (item, index) => ({
    ...item,
    key: item._id,
    sl: <span>{index + 1}</span>,
    action: (
      <div className="d-flex align-items-center gap-4">
        {sort === "PUBLISHED" ? (
          <Button
            onClick={() => handleChangeStatus(item?._id, !item?.is_active)}
            className="btn btn-sm btn-info"
          >
            Draft
          </Button>
        ) : (
          <Button
            onClick={() => handleChangeStatus(item?._id, !item?.is_active)}
            className="btn btn-sm btn-success"
          >
            Publish
          </Button>
        )}

        <i
          onClick={() => {
            handleHardDelete(item._id)
          }}
          className="bx bx-trash me-2"
          style={{ color: "red", fontSize: "20px", cursor: "pointer" }}
        />
      </div>
    ),
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col xl={2} lg={2} md={2} sm={2} className="mb-3">
                  <div
                    className="btn-group mt-2 mt-xl-0"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio2"
                      autoComplete="off"
                      defaultChecked
                      onChange={() => setSort("PUBLISHED")}
                    />
                    <label className="btn btn-success" htmlFor="btnradio2">
                      Published
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio1"
                      autoComplete="off"
                      onChange={() => setSort("DRAFT")}
                    />
                    <label className="btn btn-success" htmlFor="btnradio1">
                      Draft
                    </label>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} className="">
                  <FormGroup className="w-100 custom_store_select">
                    <div className="mb-3 ajax-select mt-lg-0 select2-container">
                      <Select
                        onInputChange={handleEnters}
                        value={selectedUser}
                        placeholder={selectedUser}
                        onChange={handlerUserFinalValue}
                        options={storeOptions}
                        classNamePrefix="select-selection"
                        isLoading={loading}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={allTableData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <>
                        {appReviewsList?.length >= 1 ? (
                          <>
                            <Row>
                              <p>
                                Total Reviews :&nbsp;
                                <Badge
                                  className={
                                    "mx-1 font-size-13 badge-soft-success"
                                  }
                                  color="black"
                                  pill
                                >
                                  {total}
                                </Badge>
                              </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No {sort} App Reviews !</p>
                        )}
                      </>
                      {/* )} */}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AppReviewTable
