import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ORDERS,
  GET_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS,
  UPDATE_ORDER_ITEM,
  GET_STARRED_ORDERS,
  GET_CANCELLED_ORDERS,
  GET_RETURNED_ORDERS,
  UPDATE_ORDER,
  UPDATE_PAYMENT,
} from "./actionTypes"
import {
  getOrdersSuccess,
  getOrdersFail,
  getOrderDetailsSuccess,
  getOrderDetailsFail,
  getUserOrderDetailsSuccess,
  getUserOrderDetailsFail,
  updateOrderItemSuccess,
  updateOrderItemFail,
  getStarredOrdersSuccess,
  getStarredOrdersFail,
  getCancelledOrdersSuccess,
  getCancelledOrdersFail,
  getReturnedOrdersSuccess,
  getReturnedOrdersFail,
  updateOrderSuccess,
  updateOrderFail,
  updatePaymentSuccess,
  updatePaymentFail,
} from "./actions"
import { get, post } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function getOrdersAPi({
  page,
  limit,
  searchText,
  store,
  sort,
  isCustomStore,
  sortByDate,
}) {
  if (isCustomStore === true && !store) {
    return get(
      `/order/admin/custom/${sort}?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`
    )
  } else if (isCustomStore === true && store) {
    return get(
      `/order/admin/custom/${sort}?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${
        sortByDate.to
      }&store=${store}`
    )
  } else if (store) {
    return get(
      `/order/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${
        sortByDate.to
      }&store=${store}`
    )
  } else
    return get(
      `/order/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`
    )
}

const getOrderDetailsAPi = orderId => {
  return get(`/order/admin/single/${orderId}`)
}

const getUserOrderDetailsAPi = ({ userId, page }) => {
  return get(`/order/admin/user/${userId}?page=${page ? page : 1}`)
}

const updateOrderItemApi = ({ order }) => {
  return post(`/order/admin/item/status`, order)
}

const updateOrderApi = ({ order }) => {
  return post(`/order/admin/status`, order)
}

const updatePaymentApi = ({ order }) => {
  return post(`/order/admin/paid/status`, order)
}

function getStarredOrdersAPi({ page }) {
  return get(`/order/admin/custom/starred?page=${page ? page : 1}&limit=10`)
}

function getCancelledOrdersAPi({ page }) {
  return get(`/order/admin/custom/cancelled?page=${page ? page : 1}&limit=10`)
}

function getReturnedOrdersAPi({ page }) {
  return get(`/order/admin/custom/returned?page=${page ? page : 1}&limit=10`)
}

function* fetchOrders({ payload }) {
  try {
    const response = yield call(getOrdersAPi, payload)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

function* fetchOrderDetail({ payload: orderId }) {
  try {
    const response = yield call(getOrderDetailsAPi, orderId)
    yield put(getOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getOrderDetailsFail(error))
  }
}

function* fetchUserOrderDetail({ payload }) {
  try {
    const response = yield call(getUserOrderDetailsAPi, payload)
    yield put(getUserOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getUserOrderDetailsFail(error))
  }
}

function* onUpdateOrderItem({ payload }) {
  try {
    const response = yield call(updateOrderItemApi, payload)
    if (response) {
      yield put(updateOrderItemSuccess(response))
      Notification({
        type: "success",
        message: "Order Item Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateOrderItemFail(error))
  }
}
function* onUpdateOrder({ payload }) {
  try {
    const response = yield call(updateOrderApi, payload)
    if (response) {
      yield put(updateOrderSuccess(response))
      Notification({
        type: "success",
        message: "Order Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    console.log("title =>")
    console.log("data :", error)

    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateOrderFail(error))
  }
}
function* onUpdatePayment({ payload }) {
  try {
    const response = yield call(updatePaymentApi, payload)

    if (response) {
      yield put(updatePaymentSuccess(response))
      Notification({
        type: "success",
        message: "Order Updated Successfully!",
        title: "",
      })
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updatePaymentFail(error))
  }
}

function* fetchStarredOrders({ payload }) {
  try {
    const response = yield call(getStarredOrdersAPi, payload)
    yield put(getStarredOrdersSuccess(response))
  } catch (error) {
    yield put(getStarredOrdersFail(error))
  }
}

function* fetchCancelledOrders({ payload }) {
  try {
    const response = yield call(getCancelledOrdersAPi, payload)
    yield put(getCancelledOrdersSuccess(response))
  } catch (error) {
    yield put(getCancelledOrdersFail(error))
  }
}

function* fetchReturnedOrders({ payload }) {
  try {
    const response = yield call(getReturnedOrdersAPi, payload)
    yield put(getReturnedOrdersSuccess(response))
  } catch (error) {
    yield put(getReturnedOrdersFail(error))
  }
}

function* orderSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_ORDER_DETAILS, fetchOrderDetail)
  yield takeEvery(GET_USER_ORDER_DETAILS, fetchUserOrderDetail)
  yield takeEvery(UPDATE_ORDER_ITEM, onUpdateOrderItem)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(UPDATE_PAYMENT, onUpdatePayment)
  yield takeEvery(GET_STARRED_ORDERS, fetchStarredOrders)
  yield takeEvery(GET_CANCELLED_ORDERS, fetchCancelledOrders)
  yield takeEvery(GET_RETURNED_ORDERS, fetchReturnedOrders)
}

export default orderSaga
