import PropTypes from "prop-types"
import React, { useEffect } from "react"
import "toastr/build/toastr.min.css"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import MetaTag from "components/Common/Meta-tag"
import { LogoFull } from "assets/images/dealsToHome"

const Login = ({ history }) => {
  const dispatch = useDispatch()

  const { error, user, loading, settings } = useSelector(state => ({
    error: state.Login.error,
    user: state.Login.user,
    loading: state.Login.loading,
    settings: state.Settings.settings,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(loginUser(values, history))
  }

  const token = localStorage.getItem("token")

  useEffect(() => {
    if (token) {
      history.push("/dashboard")
    }
  }, [])

  // useEffect(() => {
  //   dispatch(getSettings())
  // }, [dispatch])

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-gray p-4">
                        <h5 className="text-gray">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle "
                          style={{
                            backgroundColor: "#f8f9fa",
                          }}
                        >
                          <img
                            src={settings?.company?.logo[0]?.url ?? LogoFull}
                            alt="logo"
                            height="15"
                            width="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {error?.message ? (
                        <Alert color="danger">{error.message}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          {loading ? (
                            <>
                              <Spinner color="white" />
                            </>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="#!" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {}. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by
                  <a target="blank" href="https://osperb.com">
                    {` osperb`}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
