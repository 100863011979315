import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSettings } from "store/actions"

export const CurrencySwitch = () => {
  const dispatch = useDispatch()

  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  const currency = settings?.company?.defaultCurrencySymbol

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  if (currency) return currency
}
