import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardTitle, Col, Container } from "reactstrap"
import { FormGroup, Row } from "reactstrap"
import { TabContent, TabPane } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  getCollectionsDetails,
  updateCollection,
  getStoreList,
  getAllProducts,
} from "store/actions"
import { debounce, map } from "lodash"
import LanguageTab from "components/Common/LanguageTab"
import ImageCard from "components/Common/ImageCardUpdate"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const UpdateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { collectionsDetails, loading, products, prodLoading } = useSelector(
    state => ({
      loading: state.Collection.loading,
      collectionsDetails: state.Collection.collectionDetails,
      storeList: state.Stores.storeList,
      products: state.Products.products,
      prodLoading: state.Products.loading,
      totalProductsList: state.Products.totalProductsList,
    })
  )

  const [store, setStore] = useState()

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [productSelect, setproductSelect] = useState({
    searchText: "",
    id: "",
    title: "Search a Product",
  })
  const [selectedProductArray, setSelectedProductArray] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  const [oldProductList, setoldProductList] = useState([])

  useEffect(() => {
    setoldProductList(collectionsDetails?.products?.map(s => s._id))
    setStore(collectionsDetails?.store?._id)
  }, [collectionsDetails])

  useEffect(() => {
    dispatch(getStoreList())
  }, [])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])

  function handleValidSubmit(values) {
    const updateCollectionData = {
      store: store,
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      logoImages:
        logoImages.length > 0 ? logoImages : collectionsDetails?.logoImages,
      bannerImages:
        bannerImages.length > 0
          ? bannerImages
          : collectionsDetails?.bannerImages,
      products: selectedProductId,
    }

    dispatch(
      updateCollection(updateCollectionData, collectionsDetails?._id, history)
    )
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    dispatch(getCollectionsDetails(params?.id))
  }, [dispatch])

  const BannerImage = collectionsDetails?.bannerImages
  const LogoImage = collectionsDetails?.logoImages

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  useEffect(() => {
    console.log(collectionsDetails?.products)
    //prodcut
    const updatePordToSelectFormat = map(
      collectionsDetails?.products,
      prod => ({
        title: prod?.primaryLang?.name,
        image: prod?.images[0]?.url,
        id: prod?._id,
      })
    )

    setSelectedProductArray(updatePordToSelectFormat)
    const updateProdToIdsArray = map(
      collectionsDetails?.products,
      prod => prod?._id
    )
    setSelectedProductId(updateProdToIdsArray)
  }, [collectionsDetails])

  const isMult = LanguageSwitch()?.isMult

  //products
  const debounceProductSearch = debounce(value => setproductSelect(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setproductSelect(product)
    setSelectedProductArray([...selectedProductArray, product])
    setSelectedProductId([...selectedProductId, event.value])
  }

  const productOptions = [
    {
      options:
        products?.length &&
        products?.map((result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result?.varients?.length >= 1
              ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
              : ""
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    const filteredProd = selectedProductArray?.filter(i => i.id !== id)
    setSelectedProductArray(filteredProd)

    const filteredProdId = selectedProductId?.filter(i => i !== id)
    setSelectedProductId(filteredProdId)
  }

  return (
    <>
      <MetaTag title={"Update Collections | Deals2Home"} />

      <div className="page-content">
        <Breadcrumbs
          title="Classifications"
          breadcrumbItem="Update Collections"
        />
        <Container fluid>
          <div className="container-fluid mb-5">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Store
                        </CardTitle>
                        <Row>
                          <Col className="col-6 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                type="text"
                                name="store"
                                errorMessage="Select a Store"
                                value={collectionsDetails?.store?.storeName}
                                readOnly
                                // validate={{
                                //   required: { value: true },
                                // }}
                                // onChange={(e)=>{
                                //   setStore(e.target.value)
                                // }}
                              >
                                {/* <option>Select a Store</option> */}
                                {/* {storeList?.map((item, key) => (
                                <option
                                  key={key}
                                  value={item?._id}
                                >
                                  {item?.storeName}
                                </option>
                              ))} */}
                              </AvField>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        {isMult && (
                          <LanguageTab
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                          />
                        )}
                        <TabContent activeTab={activeTab} className="">
                          <TabPane tabId="1">
                            <Row>
                              <Col className="col-12 d-flex flex-wrap">
                                <Col className="col-12 d-flex flex-wrap">
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.title"
                                      label="Title"
                                      type="text"
                                      errorMessage="Invalid product name"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={
                                        collectionsDetails?.primaryLang
                                          ?.title || ""
                                      }
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.description"
                                      label="Description"
                                      type="textarea"
                                      errorMessage="Invalid description"
                                      rows="7"
                                      value={
                                        collectionsDetails?.primaryLang
                                          ?.description || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId="2">
                            <Row>
                              <Col xl="12">
                                <Row>
                                  <Col className="col-12 d-flex flex-wrap">
                                    <Col className="col-12 d-flex flex-wrap">
                                      <div className="col-12 mb-3">
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.title"
                                          label="Title"
                                          type="text"
                                          value={
                                            collectionsDetails?.secondaryLang
                                              ?.title || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-12 mb-3">
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.description"
                                          label="Description"
                                          type="textarea"
                                          rows="7"
                                          value={
                                            collectionsDetails?.secondaryLang
                                              ?.description || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="4" lg="4" className="mb-3">
                            <CardTitle className="h4 mb-4 text_deals_green">
                              Products
                            </CardTitle>
                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleProductEnters}
                                  value={productSelect?.title}
                                  placeholder={productSelect?.title}
                                  onChange={handlerProductFinalValue}
                                  options={productOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={prodLoading}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-2 text_deals_green">
                          Selected Products
                        </CardTitle>
                        {selectedProductArray?.length >= 1 && (
                          <>
                            {map(selectedProductArray, (item, key) => (
                              <div key={key} className="mb-2">
                                <div className="d-flex align-items-center">
                                  <img
                                    className="img-thumbnail rounded-circle "
                                    style={{ height: "60px", width: "60px" }}
                                    src={item?.image}
                                    alt="product"
                                  />
                                  <p className="pb-0 mb-0 px-3">{item.title}</p>
                                  <i
                                    className="bx bx-trash-alt "
                                    style={{
                                      fontSize: "1rem",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleRemoveSeletedproduct(item.id)
                                    }
                                  ></i>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  <Row>
                    <Col className="col-12">
                      <ImageCard
                        LogoImage={LogoImage}
                        BannerImage={BannerImage}
                        onChangeImageHandler={onChangeImageHandler}
                        imagesPreview={bannerImagesPreview}
                        logoImagesPreview={logoImagesPreview}
                        onChangeLogoImageHandler={onChangeLogoImageHandler}
                        onDeleteImg={onDeleteImg}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-customer"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCollections
