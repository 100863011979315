import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchPlaces } from "store/actions"
import { map } from "lodash"
import PropTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"

export function MapSearch({ zoomTo, setMarkers }) {
  const dispatch = useDispatch()
  const [places, setPlaces] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)

  const { AllPlaces } = useSelector(state => ({
    AllPlaces: state.Settings.places.places,
  }))

  useEffect(() => {
    if (places) {
      dispatch(searchPlaces(places))
    }
  }, [places])

  const handleChangePlaces = e => {
    setPlaces(e.target.value)
    setShowDropDown(true)
  }

  const handlePlacesSelect = item => {
    setPlaces(item?.place)
    zoomTo({
      lat: item?.lat,
      lng: item?.lon,
    })
    setShowDropDown(false)
    setMarkers(current => [
      {
        lat: item?.lat,
        lng: item?.lon,
        time: new Date(),
      },
    ])
  }

  return (
    <div className="search_map">
      <AvForm>
        <AvField
          name="places"
          label="Search Places"
          type="text"
          onChange={e => handleChangePlaces(e)}
          value={places}
          placeholder="Search Places..."
          className="mb-2"
        />
      </AvForm>
      {showDropDown && (
        <div className="searched_list">
          {map(AllPlaces?.slice(0, 10), (item, key) => (
            <p
              className="d-flex align-items-center cursor-pointer"
              key={key}
              onClick={() => handlePlacesSelect(item)}
            >
              {item?.place?.slice(0, 30)}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

MapSearch.propTypes = {
  zoomTo: PropTypes.func,
  setMarkers: PropTypes.func,
}
