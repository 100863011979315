import React from "react"
import PropTypes from "prop-types"
import Cropper from "react-easy-crop"
import Dropzone from "react-dropzone"
import SlideRule from "react-slide-rule"
import { Modal, ModalBody } from "reactstrap"
import { arrayMoveImmutable } from "array-move"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { useEffect, useRef, useState, useCallback } from "react"

import "react-circular-progressbar/dist/styles.css"

import { post } from "helpers/api_helper"
import { Notification } from "components/Common/Notification"

const UseImageUploader = ({
  setImagesPreview,
  imagesPreview,
  uploadUrl,
  deleteUrl,
  imageSize,
}) => {
  console.log(imagesPreview)

  const [imageLoaded, setImageLoaded] = useState(false)
  const [selectedImageDetails, setSelectedImageDetails] = useState()
  const [imageUploading, setImageUploading] = useState({ num: 0 })
  const [imageLoading, setImageLoading] = useState({
    status: false,
    isDeleteLoad: false,
    id: "",
  })
  const [fileSize, setFileSize] = useState("")
  const load = useRef(0)

  const [onCropingImg, setOnCropingImg] = useState({ data: {}, status: false })
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    if (imageUploading.num >= 10 && imageLoading.status === true) {
      if (load.current < 90 && imageUploading.num < 90) {
        load.current += 1
        const timer = setTimeout(
          () => setImageUploading({ num: imageUploading.num + 6 }),
          1000
        )
        return () => clearTimeout(timer)
      }
    }
    if (imageUploading.num === 100) {
      setTimeout(() => {
        setImageUploading({ num: 0 })
      }, 500)
    }
  }, [imageUploading])

  //inout handler
  const onChangeImageHandler = e => {
    console.log(e)

    setImageLoading({ ...imageLoading, status: true })
    setImageUploading({ ...imageUploading, num: 10 })
    const files = e

    function bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
      if (bytes == 0) return "0 Byte"
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return {
        size: Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i],
        sizeInKb: Math.round(bytes / 1024),
      }
    }

    const { size, sizeInKb } = bytesToSize(files[0]?.size)

    setSelectedImageDetails(size)

    if (sizeInKb >= imageSize * 1024) {
      setFileSize(
        size + " file size is too large - Max Limit " + imageSize + "MB"
      )
      setImageLoading({ ...imageLoading, status: false })
      setImageUploading({ ...imageUploading, num: 0 })
    } else {
      setFileSize("")
      files.forEach((file, key) => {
        const reader = new FileReader()
        reader.onload = async () => {
          if (reader.readyState === 2) {
            //call api and upload image
            try {
              const res = await post(uploadUrl, { image: reader.result })
              console.log(res)
              setImagesPreview([...imagesPreview, res])
              setImageLoading({ ...imageLoading, status: false })
              setImageUploading({ ...imageUploading, num: 100 })
              Notification({
                type: "success",
                message: "Image uploaded",
                title: "",
              })
            } catch (error) {
              console.log(error)
              Notification({
                type: "error",
                message: "Image uploaded faild, try again",
                title: "",
              })
              setImageLoading({ ...imageLoading, status: false })
              setImageUploading({ ...imageUploading, num: 0 })
            }
          }
        }

        reader.readAsDataURL(file)
      })
    }
  }

  //delete handler
  const handleRemoveImage = async (id, hideNtf) => {
    setImageLoading({ ...imageLoading, isDeleteLoad: true, id: id })
    try {
      const res = await post(deleteUrl, {
        public_id: id,
      })
      if (res?.result === "ok") {
        if (!hideNtf) {
          setImagesPreview(imagesPreview?.filter(img => img.public_id !== id))
          Notification({
            type: "success",
            message: "Image removed",
            title: "",
          })
        }
        setImageLoading({ ...imageLoading, isDeleteLoad: false })
      } else {
        Notification({
          type: "error",
          message: "Image delete faild, try again",
          title: "",
        })
        setImageLoading({ ...imageLoading, isDeleteLoad: false })
      }
    } catch (error) {
      Notification({
        type: "error",
        message: "Image delete faild, try again",
        title: "",
      })
      setImageLoading({ ...imageLoading, isDeleteLoad: false })
      console.log(error)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImagesPreview(arrayMoveImmutable(imagesPreview, oldIndex, newIndex))
  }

  const handleCropImage = value => {
    setOnCropingImg({ data: value, status: true })
    setSelectedImage(value)
  }

  const SortablePhoto = SortableElement(item => {
    const { value } = item
    return (
      <div style={imageGalleryStyles}>
        <div className="position-relative h-100 w-100 p-1">
          <img
            src={value?.url}
            alt="Images Preview"
            className="m-1 mr-2"
            width={"100%"}
            height={"100%"}
            style={imgWithClick}
            loading={"lazy"}
            onLoad={() => setImageLoaded(true)}
          />
          {value?.url && imageLoaded && (
            <>
              <div style={{ ...cropButtonContainer, flexDirection: "row" }}>
                <span style={CropButtonStyle}>
                  <i
                    onClick={() => handleCropImage(value)}
                    className="bx bx-crop mx-1 font-size-16 bx-tada-hover"
                    title={"crop image"}
                  ></i>
                  <div
                    className="mt-1"
                    onMouseDown={() => handleRemoveImage(value?.public_id)}
                  >
                    {imageLoading.isDeleteLoad &&
                    imageLoading.id === value?.public_id ? (
                      <i className="bx bx-loader bx-spin mx-1 font-size-16 align-center"></i>
                    ) : (
                      <i
                        className="bx bx-trash-alt mx-1 font-size-16 bx-tada-hover"
                        style={{ color: "#ff7a7a" }}
                        title={"remove image"}
                      ></i>
                    )}
                  </div>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    )
  })

  const SortableGallery = SortableContainer(({ items }) => (
    <div className="sortable-queue d-flex flex-wrap mx-1">
      {items?.map((item, idx) => (
        <div className="position-relative mb-2" key={idx}>
          <SortablePhoto key={idx} index={idx} value={item} />
        </div>
      ))}

      {imageLoading?.status ? (
        <div style={imageLoaderStyles} className="col-4 mx-1">
          <CircularProgressbarWithChildren
            value={imageUploading.num}
            strokeWidth={3}
          >
            <div style={progressbarStyles}>
              <span>Uploading...</span>
              <span>{imageUploading.num}%</span>
              <span>
                {selectedImageDetails !== NaN && selectedImageDetails}
              </span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      ) : (
        ""
      )}
    </div>
  ))

  return (
    <div style={mainContainerStyles}>
      <div className="form-group mb-2 d-flex rounded-md">
        <SortableGallery
          items={imagesPreview}
          onSortEnd={onSortEnd}
          axis={"xy"}
          hideSortableGhost={true}
          distance={1}
        />
        <div className="mt-2 mx-1 custom-file">
          <Dropzone
            onDrop={acceptedFiles => {
              onChangeImageHandler(acceptedFiles)
            }}
            multiple={false}
            disabled={imageLoading?.status}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" style={dropzoneStyles}>
                <div
                  className="dz-message needsclick mt-4 h-100 p-0"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="">
                    <i
                      className={
                        "display-4 bx bxs-image-add " +
                        `${imageLoading?.status === true ? "text-muted" : ""}`
                      }
                      style={addIconStyles}
                    />
                  </div>
                  <p className="font-size-13">
                    Click to upload or drag and drop
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        {fileSize && <p className="text-danger mx-2">{fileSize}</p>}
      </div>
      {imagesPreview?.length >= 2 && (
        <span className="mx-2">Drag photo to rearrange</span>
      )}

      <ImageCropModal
        selectedImage={selectedImage}
        onCropingImg={onCropingImg}
        setOnCropingImg={setOnCropingImg}
        setImagesPreview={setImagesPreview}
        imagesPreview={imagesPreview}
        uploadUrl={uploadUrl}
        handleRemoveImage={handleRemoveImage}
      />
    </div>
  )
}

function ImageCropModal({
  onCropingImg,
  setOnCropingImg,
  setImagesPreview,
  imagesPreview,
  selectedImage,
  uploadUrl,
  handleRemoveImage,
}) {
  console.log("selectedImage", selectedImage)
  const [loading, setLoading] = useState({
    applyLoad: false,
    saveLoad: false,
  })
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [aspect, setAspect] = useState(1)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [readyToSave, setReadyToSave] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cropImageNow = useCallback(async () => {
    setLoading({ ...loading, applyLoad: true })
    try {
      const croppedImage = await getCroppedImg(
        onCropingImg?.data.url,
        croppedAreaPixels,
        rotation
      )
      setOnCropingImg({
        ...onCropingImg,
        data: {
          ...onCropingImg.data,
          url: croppedImage,
        },
      })
      setZoom(1)
      setCrop({ x: 0, y: 0 })
      setRotation(0)
      setTimeout(() => {
        setReadyToSave(true)
      }, 500)
      setLoading({ ...loading, applyLoad: false })
    } catch (e) {
      console.error(e)
      setLoading({ ...loading, applyLoad: false })
    }
  }, [croppedAreaPixels, rotation])

  const onToggleCropImage = () => {
    setOnCropingImg({ ...onCropingImg, status: !onCropingImg.status })
  }
  const onCloseModal = () => {
    setOnCropingImg({ ...onCropingImg, status: false })
  }

  const onClearChanges = () => {
    setOnCropingImg({
      ...onCropingImg,
      data: selectedImage,
    })
    setReadyToSave(false)
  }

  const onSaveCropedImage = async () => {
    setLoading({ ...loading, saveLoad: true })
    if (onCropingImg?.data.url) {
      try {
        const res = await post(uploadUrl, { image: onCropingImg?.data.url })
        console.log("res", res)
        if (res) {
          setImagesPreview(
            imagesPreview?.map(i =>
              i.public_id === selectedImage.public_id ? res : i
            )
          )
          onCloseModal()

          if (res.public_id !== selectedImage.public_id) {
            handleRemoveImage(selectedImage.public_id, true)
          }
        }
        Notification({
          type: "success",
          message: "Image uploaded",
          title: "",
        })
        setLoading({ ...loading, applyLoad: false })
      } catch (error) {
        console.log(error)
        Notification({
          type: "error",
          message: "Image uploaded faild, try again",
          title: "",
        })
        setLoading({ ...loading, applyLoad: false })
      }
    } else {
      onCloseModal()
    }
  }

  useEffect(() => {
    setReadyToSave(false)
  }, [crop, zoom, rotation, aspect])

  const [ref, setRef] = useState(null)
  const width = ref?.offsetWidth

  return (
    <Modal
      isOpen={onCropingImg.status}
      toggle={onToggleCropImage}
      centered={true}
    >
      <ModalBody className="p-0" style={{ height: "640px" }}>
        <div className="mb-2 mt-2 pt-2 pb-2 d-flex align-items-center justify-content-between">
          <i
            className="bx bx-arrow-back mx-3 font-size-17 hover-zoom"
            style={{ cursor: "pointer", color: "#808080" }}
            onClick={onCloseModal}
            title={"go back"}
          ></i>
          <span onClick={onClearChanges} className="hover-zoom cursor-pointer">
            Reset
          </span>
          <i
            className="bx bx-rotate-right mx-3 font-size-17 hover-zoom"
            style={{ cursor: "pointer", color: "#808080" }}
            title="rotate 90% forwoard"
            onClick={() =>
              rotation >= 360 ? setRotation(0) : setRotation(rotation + 90)
            }
          ></i>
        </div>
        <div className="">
          <Cropper
            image={onCropingImg?.data.url}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            style={{ containerStyle: containerStyles }}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between m-2 mb-2 mx-1">
          <div style={rulerStyles} ref={setRef} className="ruler">
            <p className="pb-1 m-0">{rotation}°</p>
            <SlideRule
              value={rotation}
              onChange={setRotation}
              width={width}
              markStyle={{ top: -10 }}
              max={360}
              min={-100}
            />
          </div>
        </div>

        <div className="d-flex align-items-end justify-content-between mt-4 mx-2">
          <div className="d-flex align-items-center">
            <div className="mx-2 d-flex align-items-center justify-content-center flex-column">
              <div style={aspBoxStyels} onClick={() => setAspect(1)}>
                <button style={aspButtonStyels}></button>
              </div>
              <span className="font-size-11">1:1</span>
            </div>
            <div className="mx-2 d-flex align-items-center justify-content-center flex-column">
              <div style={aspBoxStyels} onClick={() => setAspect(3 / 2)}>
                <button style={{ ...aspButtonStyels, height: "17px" }}></button>
              </div>
              <span className="font-size-11">3:2</span>
            </div>
            <div className="mx-2 d-flex align-items-center justify-content-center flex-column">
              <div style={aspBoxStyels} onClick={() => setAspect(2 / 3)}>
                <button style={{ ...aspButtonStyels, width: "17px" }}></button>
              </div>
              <span className="font-size-11">2:3</span>
            </div>
          </div>
          {readyToSave ? (
            <span
              onClick={onSaveCropedImage}
              style={applyButtonStyles}
              className="mx-2"
            >
              save
              {loading?.saveLoad && (
                <i className="bx bx-loader bx-spin mx-1 font-size-16 align-center"></i>
              )}
            </span>
          ) : (
            <span
              onClick={cropImageNow}
              style={applyButtonStyles}
              className="mx-2"
            >
              Apply
              {loading?.applyLoad && (
                <i className="bx bx-loader bx-spin mx-1 font-size-16 align-center"></i>
              )}
            </span>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

//getCroppedImg url and files in base64 formate
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => reject(error))
    image.setAttribute("crossOrigin", "anonymous")
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return canvas.toDataURL("image/jpeg")

  // As a blob
  //   return new Promise((resolve, reject) => {
  //     canvas.toBlob(file => {
  //       resolve({ file: file, url: URL.createObjectURL(file) })
  //     }, "image/jpeg")
  //   })
}

//styles
const aspButtonStyels = {
  height: "25px",
  width: "25px",
  border: "1px solid #23a9a9",
  background: "transparent",
}
const aspBoxStyels = {
  height: "50px",
  width: "50px",
  background: "#f3f5f7",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}
const rulerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}
const applyButtonStyles = {
  padding: "7px 55px",
  background: "#d5f2e1",
  color: "#000",
  borderRadius: "10px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}
const containerStyles = {
  position: "relative",
  width: "100%",
  height: 400,
  background: "#333",
}
const cropButtonContainer = {
  position: "absolute",
  top: "0",
  left: "0",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  flexDirection: "column",
  cursor: "grab",
}
const CropButtonStyle = {
  background: "#f6f6f6",
  color: "#000",
  borderRadius: "15px",
  padding: "2px 10px 2px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  marginTop: "13px",
  marginRight: "5px",
  boxShadow: "rgb(0 0 0 / 10%) 0px 10px 50px",
}
const addIconStyles = {
  fontSize: "30px",
  color: "#2e9d5e",
}
const dropzoneStyles = {
  padding: "10px",
}
const mainContainerStyles = {
  border: "1px solid #f8f8fb",
  borderRadius: "10px",
  padding: "15px",
  background: "#f9f9fa38",
}
const imgWithClick = {
  cursor: "pointer",
  maxWidth: "200px",
  maxHeight: "150px",
  height: "150px",
  borderRadius: "10px",
}
const imageLoaderStyles = {
  height: "150px",
  width: "150px",
  background: "#f8f8fb",
  padding: "1rem",
  marginTop: "8px",
  borderRadius: "6px",
}
const progressbarStyles = {
  fontSize: 11,
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  marginTop: "10px",
}
const imageGalleryStyles = {
  maxWidth: "200px",
  maxHeight: "150px",
}
const imageUploadInputStyles = {
  background: "#2e9d5e",
  padding: "8px",
  borderRadius: "5px",
  color: "#fff",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "200px",
}
const imageCloseButtonStyle = {
  width: "25px",
  height: "25px",
  background: "rgb(255, 255, 255)",
  border: "1px solid rgb(228, 228, 228)",
  borderRadius: "50%",
  color: "rgb(203, 64, 64)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "0px",
  right: "0px",
  cursor: "pointer",
}
const imageRemoveButtonStyle = {
  width: "fit-content",
  background: "rgb(255, 255, 255)",
  border: "1px solid rgb(228, 228, 228)",
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "15px",
  padding: "1px 8px 1px 4px",
  marginTop: "4px",
}
const crossIconStyles = { fontSize: "15px", paddingTop: "5px" }
const imageSortableContainer = { minHeight: "150px", minWidth: "150px" }

export default UseImageUploader

//props
UseImageUploader.propTypes = {
  setImagesPreview: PropTypes.func,
  imagesPreview: PropTypes.any,
  imageSize: PropTypes.number,
  deleteUrl: PropTypes.string,
  uploadUrl: PropTypes.string,
}
ImageCropModal.propTypes = {
  onCropingImg: PropTypes.any,
  setOnCropingImg: PropTypes.func,
  setImagesPreview: PropTypes.func,
  imagesPreview: PropTypes.any,
  uploadUrl: PropTypes.string,
  selectedImage: PropTypes.any,
  handleRemoveImage: PropTypes.func,
}

//referances
{
  /* <div className="row">
        {imagesPreview?.map((img, key) => (
          <div key={key} className="position-relative col-4 mt-2">
            <img
              src={img?.url}
              alt="Images Preview"
              className="m-1 P-2 mr-2"
              width={"100%"}
            />
            <div
              style={imageCloseButtonStyle}
              onClick={() => handleRemoveImage(img?.public_id)}
            >
              {imageLoading.isDeleteLoad ? (
                <i className="bx bx-loader bx-spin font-size-16 align-center"></i>
              ) : (
                <i className="dripicons-cross" style={crossIconStyles}></i>
              )}
            </div>
          </div>
        ))}

        {imageLoading?.status ? (
          <div style={imageLoaderStyles} className="col-4 mt-2">
            <CircularProgressbarWithChildren
              value={imageLoading.load}
              strokeWidth={3}
            >
              <div style={progressbarStyles}>
                <span>Uploading...</span>
                <span>{imageLoading.load}%</span>
                <span>{imageDetails}</span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        ) : (
          ""
        )}
      </div> */
}

//code for delete icon if it not working
{
  /* {handleIcons === true ? (
            <div
              style={{ ...imageCloseButtonStyle, top: "8px" }}
              onClick={() => handleRemoveImage(item.public_id)}
            >
              {imageLoading.isDeleteLoad &&
              imageLoading.id === item?.public_id ? (
                <i className="bx bx-loader bx-spin font-size-16 align-center"></i>
              ) : (
                <i className="dripicons-cross" style={crossIconStyles}></i>
              )}
            </div>
          ) : (
            <div></div>
          )} */
}

//sort image
// const onSortStart = () => {
//   setTimeout(() => {
//     setHandleIcons(false)
//   }, 100)
// }
{
  /* <label
          className="custom-file-label"
          htmlFor="customFile"
          style={imageUploadInputStyles}
        >
          <i className="bx bx-plus font-size-20"></i> Upload Product Image
        </label>
        <input
          type="file"
          name="product_images"
          className="custom-file-input"
          id="customFile"
          onChange={onChangeImageHandler}
          style={{ opacity: 0 }}
        /> */
}
{
  /* {value?.url ? (
            <div
              className="btn_close_hover"
              style={imageCloseButtonStyle}
              onMouseDown={() => handleRemoveImage(value?.public_id)}
            >
              {imageLoading.isDeleteLoad &&
              imageLoading.id === value?.public_id ? (
                <i className="bx bx-loader bx-spin font-size-16 align-center"></i>
              ) : (
                <i className="dripicons-cross" style={crossIconStyles}></i>
              )}
            </div>
          ) : (
            ""
          )} */
}
// onLoad={() => setImageLoaded(setTimeout(true, 900))}

{
  /*delete and crop icons only */
}
{
  /* <div style={{ ...cropButtonContainer, flexDirection: "row" }}>
<span style={CropButtonStyle}>
  <i className="bx bx-crop mx-1 font-size-16"></i>
  <div
    className="mt-1"
    onMouseDown={() => handleRemoveImage(value?.public_id)}
  >
    <i className="bx bx-trash-alt mx-1 font-size-16"></i>
    {imageLoading.isDeleteLoad &&
      imageLoading.id === value?.public_id && (
        <i className="bx bx-loader bx-spin font-size-16 align-center"></i>
      )}
  </div>
</span>
</div> */
}

//delete and crop
{
  /* <div style={cropButtonContainer}>
                <span className="btn_crop_hover" style={CropButtonStyle}>
                  <i className="bx bx-crop mx-1"></i>
                  Crop
                </span>
                <div
                  className="btn_close_hover"
                  style={imageRemoveButtonStyle}
                  onMouseDown={() => handleRemoveImage(value?.public_id)}
                >
                  {imageLoading.isDeleteLoad &&
                  imageLoading.id === value?.public_id ? (
                    <i className="bx bx-loader bx-spin mx-1 align-center"></i>
                  ) : (
                    <i className="bx bx-trash-alt mx-1"></i>
                  )}
                  <span>Delete</span>
                </div>
              </div> */
}

{
  /* <div
                className="btn_close_hover"
                style={imageCloseButtonStyle}
                onMouseDown={() => handleRemoveImage(value?.public_id)}
              >
                {imageLoading.isDeleteLoad &&
                imageLoading.id === value?.public_id ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-center"></i>
                ) : (
                  <i className="dripicons-cross" style={crossIconStyles}></i>
                )}
              </div> */
}
// const [imagesPreview, setImagesPreview] = useState([
//   {
//     url: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
//     public_id: "products/5434345",
//   },
//   {
//     url: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
//     public_id: "products/54345",
//   },
//   {
//     url: "https://source.unsplash.com/Dm-qxdynoEd/800x799",
//     public_id: "products/123456",
//   },
// ])
