import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ImageInput from "./MyInput"
import PropTypes from "prop-types"

function ImageCard({
  BannerImage,
  onChangeImageHandler,
  imagesPreview,
  onChangeLogoImageHandler,
  onDeleteImg,
  LogoImage,
  logoImagesPreview,
}) {
  return (
    <>
      <Card>
        <CardBody>
          <div className="form-group px-3">
            <CardTitle className="mb-4">Banner images :</CardTitle>

            <div className="custom-file d-flex justify-content-start align-items-center">
              <input
                type="file"
                name="banner_images"
                className="custom-file-input"
                id="banner_input"
                onChange={onChangeImageHandler}
                multiple
                hidden
              />
              <ImageInput htmlFor="banner_input" />
              {BannerImage?.length >= 1 && (
                <Row className="mx-3">
                  <Col className="d-flex flex-wrap">
                    {BannerImage &&
                      BannerImage.map((img, key) => (
                        <img
                          key={key}
                          src={img?.url}
                          alt={img.url}
                          className="mb-4"
                          style={{
                            marginRight: "1rem",
                            height: "100px",
                            width: "100px",
                          }}
                          width="100"
                          height="100"
                        />
                      ))}
                  </Col>
                </Row>
              )}

              <div className="mx-5 d-flex justify-content-end flex-wrap align-items-center">
                {imagesPreview?.map((img, index) => (
                  <div
                    style={{
                      position: "relative",
                    }}
                    className="mb-2"
                    key={index}
                  >
                    <img
                      src={img?.url}
                      alt="Images Preview"
                      className="mr-2"
                      style={{ marginRight: ".5rem" }}
                      width="100"
                      height="100"
                    />
                    <i
                      className="bx bx-x-circle"
                      style={{
                        color: "red",
                        fontSize: "1.2rem",
                        position: "absolute",
                        top: "2px",
                        right: "12px",
                        background: "#e9e9ea",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => onDeleteImg(img?.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group px-3 mt-5">
            <CardTitle className="mb-4">Logo images :</CardTitle>

            <div className="custom-file d-flex justify-content-start align-items-center">
              <input
                type="file"
                name="product_images"
                className="custom-file-input"
                id="logo_input"
                onChange={onChangeLogoImageHandler}
                multiple
                hidden
              />
              <ImageInput htmlFor="logo_input" />

              {LogoImage?.length >= 1 && (
                <Row className="mx-3">
                  <Col className="d-flex flex-wrap">
                    {LogoImage &&
                      LogoImage?.map((img, key) => (
                        <img
                          key={key}
                          src={img.url}
                          alt={img.url}
                          className=""
                          style={{
                            marginRight: "1rem",
                            height: "100px",
                            width: "100px",
                          }}
                          width="100"
                          height="100"
                        />
                      ))}
                  </Col>
                </Row>
              )}
              <div>
                {logoImagesPreview?.map(img => (
                  <Row className="mx-3" key={img}>
                    <Col className="d-flex flex-wrap">
                      <img
                        src={img}
                        alt="Images Preview"
                        className=""
                        width="100"
                        height="100"
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ImageCard

ImageCard.propTypes = {
  BannerImage: PropTypes.any,
  imagesPreview: PropTypes.any,
  LogoImage: PropTypes.any,
  logoImagesPreview: PropTypes.any,
  onChangeImageHandler: PropTypes.func,
  onChangeLogoImageHandler: PropTypes.func,
  onDeleteImg: PropTypes.func,
}
