import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  GET_USER_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS_SUCCESS,
  GET_USER_ORDER_DETAILS_FAIL,
  UPDATE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAIL,
  GET_STARRED_ORDERS,
  GET_STARRED_ORDERS_SUCCESS,
  GET_STARRED_ORDERS_FAIL,
  GET_CANCELLED_ORDERS,
  GET_CANCELLED_ORDERS_SUCCESS,
  GET_CANCELLED_ORDERS_FAIL,
  GET_RETURNED_ORDERS,
  GET_RETURNED_ORDERS_SUCCESS,
  GET_RETURNED_ORDERS_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  // orders: [],
  orders: {
    orders: [],
    total: "",
    page: "",
  },
  orderDetails: {
    orderItems: [],
  },
  userOrderDetails: {},
  starredOrders: [],
  cancelledOrders: [],
  returnedOrders: [],
  orderVarients: {},
  error: {},
  loading: false,
}

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS:
    case GET_ORDER_DETAILS:
    case GET_USER_ORDER_DETAILS:
    case UPDATE_ORDER_ITEM:
    case GET_STARRED_ORDERS:
    case UPDATE_ORDER:
    case GET_CANCELLED_ORDERS:
    case GET_RETURNED_ORDERS:
    case UPDATE_PAYMENT:
      return {
        ...state,
        loading: true,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        total: action.payload.total,
        loading: false,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        orderVarients: action.payload.orderVarients,
        loading: false,
      }

    case GET_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //USER Order Details
    case GET_USER_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        userOrderDetails: action.payload,
        loading: false,
      }

    case GET_USER_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //Update
    case UPDATE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        loading: false,
      }

    case UPDATE_ORDER_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders?.map(i =>
          i._id === action.payload._id ? action.payload : i
        ),
        orderDetails: action.payload,
        loading: false,
      }

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        loading: false,
      }

    case UPDATE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STARRED_ORDERS_SUCCESS:
      return {
        ...state,
        starredOrders: action.payload,
        loading: false,
      }

    case GET_STARRED_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CANCELLED_ORDERS_SUCCESS:
      return {
        ...state,
        cancelledOrders: action.payload,
        loading: false,
      }

    case GET_CANCELLED_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_RETURNED_ORDERS_SUCCESS:
      return {
        ...state,
        returnedOrders: action.payload,
        loading: false,
      }

    case GET_RETURNED_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Orders
