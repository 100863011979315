import React, { Fragment, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import Switch from "react-switch"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

//actions
import {
  getShopProducts,
  deleteShopProduct,
  changeShopProductStatus,
} from "store/shopProducts/actions"

import "../../assets/scss/datatables.scss"

const Products = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { shopProducts, total, loading } = useSelector(state => ({
    shopProducts: state.ShopProducts.shopProducts,
    total: state.ShopProducts.total,
    loading: state.ShopProducts.loading,
  }))

  const handleDelete = id => {
    dispatch(deleteShopProduct(id, history))
  }

  const handleSwitchChange = id => {
    dispatch(changeShopProductStatus(id, history))
  }

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getShopProducts(page))
  }, [dispatch, page, searchText])

  // const totalPages = Math.ceil(shopProducts?.length / 10)
  // const pages = range(1, totalPages + 1)

  //no new changes
  const columns = [
    {
      dataField: "zestonProduct.name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "shop.shopName",
      text: "Shop",
      sort: true,
    },
    {
      dataField: "imageData",
      text: "Image",
      sort: true,
    },
    {
      dataField: "customID",
      text: "Custom ID",
      sort: true,
    },
    {
      dataField: "zestonProduct.category.name",
      text: "Category",
      sort: true,
    },
    {
      dataField: "colorData",
      text: "Color",
    },
    {
      dataField: "sizeData",
      text: "Sizes",
    },
    {
      dataField: "activeData",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const productData = map(shopProducts, (item, index) => ({
    ...item,
    imageData: (
      <img
        src={item?.zestonProduct?.images && item?.zestonProduct?.images[0]?.url}
        width="50px"
      />
    ),
    colorData: (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Col
          md="2"
          className={"avatar-title rounded-circle bg-soft"}
          style={{
            backgroundColor: `${item?.zestonProduct?.color?.value}`,
            width: "30px",
            height: "30px",
            display:
              item?.zestonProduct?.color?.value == "Not Specified"
                ? "none"
                : "",
            boxShadow: "0px 0px 5px #888888",
          }}
        ></Col>
        <Col>{item?.zestonProduct?.color?.name}</Col>
      </Row>
    ),
    sizeData: (
      <>
        {item?.sizes?.map((size, key) => (
          <Row key={key}>
            {size?.value} : ₹ {size?.retailPrice} {`(${size?.stock})`}
          </Row>
        ))}
      </>
    ),
    activeData: (
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`shopProduct/${item?._id}`}>
          {/* View Details */}
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        <Button
          id={`delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          View Details
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`delete${index}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: shopProducts?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setZproductList(
      zproducts.filter(zproduct =>
        Object.keys(zproduct).some(key =>
          zproduct[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to="shopProduct/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Shop Product
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Products
