/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

/* ORDER_DETAILS */
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS"
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS"
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL"

/* USER_ORDER_DETAILS */
export const GET_USER_ORDER_DETAILS = "GET_USER_ORDER_DETAILS"
export const GET_USER_ORDER_DETAILS_SUCCESS = "GET_USER_ORDER_DETAILS_SUCCESS"
export const GET_USER_ORDER_DETAILS_FAIL = "GET_USER_ORDER_DETAILS_FAIL"

/* Edit ORDER ITEM*/
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM"
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS"
export const UPDATE_ORDER_ITEM_FAIL = "UPDATE_ORDER_ITEM_FAIL"

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/* STARRED_ORDERS */
export const GET_STARRED_ORDERS = "GET_STARRED_ORDERS"
export const GET_STARRED_ORDERS_SUCCESS = "GET_STARRED_ORDERS_SUCCESS"
export const GET_STARRED_ORDERS_FAIL = "GET_STARRED_ORDERS_FAIL"

/* CANCELLED_ORDERS */
export const GET_CANCELLED_ORDERS = "GET_CANCELLED_ORDERS"
export const GET_CANCELLED_ORDERS_SUCCESS = "GET_CANCELLED_ORDERS_SUCCESS"
export const GET_CANCELLED_ORDERS_FAIL = "GET_CANCELLED_ORDERS_FAIL"

/* RETURNED_ORDERS */
export const GET_RETURNED_ORDERS = "GET_RETURNED_ORDERS"
export const GET_RETURNED_ORDERS_SUCCESS = "GET_RETURNED_ORDERS_SUCCESS"
export const GET_RETURNED_ORDERS_FAIL = "GET_RETURNED_ORDERS_FAIL"

// update payment
export const UPDATE_PAYMENT = "UPDATE_PAYMENT"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL"
