import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

//Maps
import Leaflet from "leaflet"
import { Map, TileLayer, Marker } from "react-leaflet"
import "leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
})

import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  deleteStore,
  updateStore,
  getStoreDetails,
  changeStoreStatus,
} from "store/stores/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import MetaTag from "components/Common/Meta-tag"
import MyGoogleMap from "components/Gmap/googleMap"
import { getMapPublicKey } from "store/actions"

const StoreDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [markers, setMarkers] = useState({})

  const ApproveList = {
    operation: "APPROVE",
  }

  const ActiveList = {
    operation: "ACTIVE",
  }

  const { storeDetails, mapKey } = useSelector(state => ({
    storeDetails: state.Stores.storeDetails,
    mapKey: state.Settings.map,
  }))

  const handleDelete = () => {
    dispatch(deleteStore(storeDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getStoreDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  //Approve
  const handleSwitchChangeApprove = values => {
    const updateApproveData = {
      storeId: storeDetails?._id,
      operation: ApproveList.operation,
    }
    dispatch(changeStoreStatus(updateApproveData, storeDetails?._id))
  }

  const handleSwitchChangeActive = values => {
    const updateActiveData = {
      storeId: storeDetails?._id,
      operation: ActiveList.operation,
    }
    dispatch(changeStoreStatus(updateActiveData, storeDetails?._id))
  }

  const handleStatus = () => {
    if (storeDetails?.isApproved == false) {
      return {
        status: "Approve",
        className: "primary",
        icon: "fas fa-check me-2",
      }
    }
    if (storeDetails?.isActive == true) {
      return {
        status: "Suspend",
        className: "warning",
        icon: "fas fa-user-slash me-2",
      }
    } else if (storeDetails?.isActive == false) {
      return {
        status: "Reauthorize",
        className: "success",
        icon: "bx bx-user me-2",
      }
    }
  }

  const handleClick = () => {
    if (handleStatus().status == "Approve") {
      return handleSwitchChangeApprove(ApproveList?.operation)
    }
    if (handleStatus().status == "Suspend") {
      return handleSwitchChangeActive(ActiveList?.operation)
    } else if (handleStatus().status == "Reauthorize") {
      return handleSwitchChangeActive(ActiveList?.operation)
    }
  }

  const MAP_PUBLIC_KEY = mapKey?.key

  useEffect(() => {
    setMarkers(current => [
      {
        lat: storeDetails?.location?.coordinates[0],
        lng: storeDetails?.location?.coordinates[1],
        time: new Date(),
      },
    ])
  }, [storeDetails])

  const handleSwitchChange = (value, id) => {
    const updateStoreData = {
      isClosed: value,
      storeName: storeDetails?.storeName,
    }
    dispatch(updateStore(updateStoreData, storeDetails?._id, "", dispatch))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title={"Store Details "} />

        <Container fluid>
          <Breadcrumbs title="Store" breadcrumbItem="Store Details" />
          {!isEmpty(storeDetails) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <div className="">
                          <div
                            className="col-12 d-flex"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Link to={`/store/update/${storeDetails?._id}`}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                              >
                                <i className="bx bx-edit me-2" /> Update
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              color="danger"
                              className="ms-1 btn mt-2"
                              onClick={() => {
                                handleDelete(storeDetails?._id)
                              }}
                            >
                              <i className="bx bx-trash me-2" />
                              Delete
                            </Button>

                            <Button
                              type="button"
                              color={handleStatus()?.className}
                              className="ms-1 btn mt-2"
                              onClick={handleClick}
                            >
                              <i className={handleStatus()?.icon} />
                              {handleStatus()?.status}
                            </Button>

                            <Button
                              type="button"
                              color={!storeDetails?.isClosed ? "dark" : "info"}
                              className="ms-1 btn mt-2 d-flex align-items-center"
                              onClick={() => {
                                handleSwitchChange(!storeDetails?.isClosed)
                              }}
                            >
                              <i className="bx bx-timer fs-5 me-2" />
                              {storeDetails?.isClosed
                                ? `Open Store`
                                : `Close Store`}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>
                        <Col md="12">
                          <div className="mt-0">
                            <h5 className="mb-2">
                              {" "}
                              {`${storeDetails?.storeNo} -`}{" "}
                              {storeDetails?.storeName}{" "}
                              <Badge
                                className={`badge-soft-${
                                  storeDetails?.isClosed ? "danger" : "success"
                                }  `}
                              >
                                {storeDetails?.isClosed ? "Closed" : "Open"}
                              </Badge>
                            </h5>

                            <hr />
                            <div>
                              <i className="bx bx-map me-2" />
                              <Label>Store Address :</Label>
                              <Col className="mx-4">
                                {storeDetails?.storeAddress?.addressLine1},{" "}
                                <br />
                                {storeDetails?.storeAddress?.addressLine2},{" "}
                                <br />
                                {storeDetails?.storeAddress?.landmark},{" "}
                                {storeDetails?.storeAddress?.area}, <br />
                                {storeDetails?.storeAddress?.district},{" "}
                                {storeDetails?.storeAddress?.state}, <br />
                                {storeDetails?.storeAddress?.pinCode}
                              </Col>
                            </div>
                          </div>

                          <Col className="mt-2">
                            <div>
                              <i className="fas fa-phone me-2" />
                              {storeDetails?.phone}
                            </div>
                            {/* <div className="mt-2">
                              <i className="fas fa-history me-2" />
                              {storeDetails?.workingTime}
                            </div> */}
                          </Col>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="6">
                    <div className="product-detai-imgs">
                      <Card>
                        <CardBody>
                          <Col md="12">
                            <div className="mt-0">
                              <h5 className="mb-2">
                                {" "}
                                {/* {`${storeDetails?.dealerNo} -`}{" "} */}
                                {storeDetails?.storeAdminDetails?.name}
                              </h5>
                              <hr />
                              <div>
                                <i className="bx bx-map me-2" />
                                <Label>Store Admin Address :</Label>

                                <Col className="mx-4">
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.addressLine1
                                  }
                                  , <br />
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.addressLine2
                                  }
                                  , <br />
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.landmark
                                  }
                                  ,{" "}
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.area
                                  }
                                  , <br />
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.district
                                  }
                                  ,{" "}
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.state
                                  }
                                  , <br />
                                  {
                                    storeDetails?.storeAdminDetails?.address
                                      ?.pinCode
                                  }
                                </Col>
                              </div>
                            </div>

                            <Col className="mt-2">
                              <div>
                                <i className="fas fa-phone me-2" />
                                {storeDetails?.storeAdminDetails?.phone}
                              </div>
                              {/* <div className="mt-2 text-white">
                                <i className="fas fa-history me-2" />
                                {storeDetails?.workingTime}
                              </div> */}
                            </Col>
                          </Col>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        {/* <Map
                          center={position}
                          zoom={mapDetail.zoom}
                          style={{ height: "400px" }}
                        >
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={position}></Marker>
                        </Map> */}
                        {markers && (
                          <MyGoogleMap
                            MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                            notMark={false}
                            markers={markers}
                          />
                        )}
                      </Col>
                      <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 px-5 pt-5">
                        <Label>
                          <i className="bx bx-map" /> Location:{" "}
                        </Label>
                        <div>
                          <h5>
                            Latitude: {storeDetails?.location?.coordinates[0]}
                          </h5>
                        </div>
                        <div className="d-flex brbtm">
                          <h5>
                            Longitude: {storeDetails?.location?.coordinates[1]}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

StoreDetail.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.any,
  onGetStoreDetail: PropTypes.func,
}

export default StoreDetail
