import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import moment from "moment"

//Import Star Ratings
import StarRatings from "react-star-ratings"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  deleteShopProduct,
  getShopProductDetails,
} from "store/shopProducts/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import MetaTag from "components/Common/Meta-tag"
// import ShopProductDetails from ".";

const ShopProductDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [activeTab, setActiveTab] = useState(0)

  const { shopProductDetails, productVarients, reviews, loading } = useSelector(
    state => ({
      shopProductDetails: state.ShopProducts.shopProductDetails,
      productVarients: state.ShopProducts.productVarients,
      reviews: state.ShopProducts.reviews,
      loading: state.ShopProducts.loading,
    })
  )

  // const review = moment(order && order.createdAt).format("DD-MM-YYYY");

  const handleDelete = () => {
    dispatch(deleteShopProduct(shopProductDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getShopProductDetails(params.id))
  }, [dispatch])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (img, id) => {
    const expandImg = {}
    expandImg.src = document.getElementById("expandedImg")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Shop Product Details" />

        <Container fluid>
          <Breadcrumbs
            title="Shop Product"
            breadcrumbItem="Shop Product Details"
          />
          {!isEmpty(shopProductDetails) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <i className="bx bx-cart mx-2"></i>
                    <span style={{ fontWeight: "bold" }}>
                      {shopProductDetails?.shop?.shopName}
                    </span>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md="2" xs="3">
                              <Nav className="flex-column" pills>
                                {shopProductDetails?.zestonProduct?.images?.map(
                                  (image, index) => (
                                    <NavItem key={index}>
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === { index },
                                        })}
                                        onClick={() => {
                                          toggleTab(index)
                                        }}
                                      >
                                        <img
                                          src={image.url}
                                          alt=""
                                          onClick={() => {
                                            imageShow(image.url, index)
                                          }}
                                          className="img-fluid mx-auto d-block rounded"
                                        />
                                      </NavLink>
                                    </NavItem>
                                  )
                                )}
                              </Nav>
                            </Col>
                            <Col md={{ size: 7, offset: 1 }} xs="9">
                              <TabContent activeTab={activeTab}>
                                {shopProductDetails?.zestonProduct?.images?.map(
                                  (image, index) => (
                                    <TabPane key={index} tabId={index}>
                                      <div>
                                        <img
                                          src={image.url}
                                          alt=""
                                          id="expandedImg1"
                                          className="img-fluid mx-auto d-block"
                                        />
                                      </div>
                                    </TabPane>
                                  )
                                )}
                              </TabContent>
                              <div className="text-center">
                                <div>
                                  <Link
                                    to={`/shopproduct/update/${shopProductDetails?._id}`}
                                  >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn  mt-2 me-1"
                                    >
                                      <i className="bx bx-edit me-2" /> Update
                                    </Button>
                                  </Link>
                                  <Button
                                    type="button"
                                    color="danger"
                                    className="ms-1 btn mt-2"
                                    onClick={() => {
                                      handleDelete(shopProductDetails?._id)
                                    }}
                                  >
                                    <i className="bx bx-trash me-2" />
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <Link to="#" className="text-primary">
                            {shopProductDetails?.zestonProduct?.category?.name}
                          </Link>
                          <h4 className="mt-1 mb-3">
                            {shopProductDetails?.zestonProduct?.name}
                          </h4>
                          <p className="text-muted mb-4">
                            {shopProductDetails?.zestonProduct?.description}
                          </p>

                          <div className="product-color">
                            <Row className="">
                              <h5 className="font-size-15">
                                Color :{" "}
                                {shopProductDetails.zestonProduct?.color &&
                                  shopProductDetails.zestonProduct?.color.name}
                              </h5>

                              <div
                                md="1"
                                className={
                                  "avatar-title rounded-circle bg-soft mb-3 mx-3"
                                }
                                style={{
                                  backgroundColor: `${shopProductDetails?.zestonProduct?.color?.value}`,
                                  width: "30px",
                                  height: "30px",
                                  display:
                                    shopProductDetails?.zestonProduct?.color
                                      ?.value == "Not Specified"
                                      ? "none"
                                      : "",
                                  boxShadow: "0px 0px 5px #888888",
                                }}
                              ></div>

                              <h5 className="font-size-15 m-0">Varients : </h5>

                              {productVarients &&
                                productVarients.map((varient, key) => (
                                  // <Link key={key} to={`/shopproduct/${varient?._id}`}>
                                  <Col
                                    md="3"
                                    key={key}
                                    className={
                                      "avatar-title rounded-circle bg-soft mt-1 mx-3"
                                    }
                                    style={{
                                      backgroundColor: `${varient?.zestonProduct?.color?.value}`,
                                      width: "30px",
                                      height: "30px",
                                      display:
                                        varient?.zestonProduct?.color?.value ==
                                        "Not Specified"
                                          ? "none"
                                          : "",
                                      boxShadow: "0px 0px 5px #888888",
                                    }}
                                  ></Col>
                                  // </Link>
                                ))}
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <h5 className="mb-3">Specifications :</h5>
                      <Col md="6" className="mx-3">
                        {shopProductDetails?.zestonProduct?.specs &&
                          shopProductDetails?.zestonProduct?.specs.map(
                            (item, i) => (
                              <div key={i}>
                                <Row>
                                  <Col md="3">
                                    <i
                                      className={classnames(
                                        " fa fa-caret-right font-size-16 align-middle text-primary me-2"
                                      )}
                                    />
                                    {item.title && `${item.title}:`}
                                  </Col>
                                  <Col md="1">{`:`}</Col>
                                  <Col md="3">
                                    {item.details && `${item.details}`}
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                      </Col>
                    </div>
                    <Col xl="6">
                      <div className="mt-5">
                        <h5 className="mb-3">Sizes :</h5>

                        <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>
                              <tr>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Sl. No.
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Value
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Zeston Price
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Retail Price
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Margin
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Stock
                                </th>
                              </tr>

                              {shopProductDetails?.sizes &&
                                shopProductDetails?.sizes.map((size, i) => (
                                  <tr key={i}>
                                    <td style={{ width: "30px" }}> {i + 1}</td>
                                    <td scope="row">{size.value}</td>
                                    <td>₹ {size.zestonPrice}</td>
                                    <td>₹ {size.retailPrice}</td>
                                    <td>
                                      ₹ {size.retailPrice - size.zestonPrice}
                                    </td>
                                    <td>{size.stock} nos</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                    {/* <Reviews
                      comments={[...comments]}
                      productId={params.id || 1}
                      onClickReply={onClickReply}
                      onCancelReply={onCancelReply}
                      onAddReply={onAddReply}
                      onAddComment={onAddComment}
                    /> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h5>Reviews : </h5>
                    <Col>
                      <ul>
                        {reviews &&
                          reviews?.map((review, index) => (
                            <li key={index}>
                              <div className="text-muted mb-1">
                                <StarRatings
                                  rating={review?.rating}
                                  starRatedColor="#F1B44C"
                                  starEmptyColor="#dbd9d9"
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="14px"
                                  starSpacing="1px"
                                />
                              </div>
                              {review?.message} <br />
                              {review?.createdAt &&
                                moment(review && review?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                            </li>
                          ))}
                      </ul>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ShopProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default ShopProductDetail
