import { map, range } from "lodash"
import { Button, Spinner } from "reactstrap"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Link, useHistory, useLocation } from "react-router-dom"

import MyPagination from "../MyPagination"

//actions
import { getSettings } from "store/actions"
import { getAllClassifications } from "store/classifications/actions"
import { deleteCategory } from "store/actions"

import "../../assets/scss/datatables.scss"

import { LanguageSwitch } from "common/LanguageSwitch"
import DeleteModal from "components/Common/DeleteModal"
import noImage from "../../assets/images/product/noimage3.png"

const Classifications = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { classifications, loading } = useSelector(state => ({
    classifications: state.Classifications.classifications,
    loading: state.Classifications.loading,
    // classificationInputs:
    //   state.Settings.settings?.setup?.classificationInputs,
  }))

  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState("10")
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState()

  const [isOpen, setIsOpen] = useState({
    stauts: false,
    id: "",
    loading: false,
  })

  const query = location?.search.slice(1)

  //pagination
  const total = classifications?.length
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)


  useEffect(() => {
    if (total) {
      setLimit(total)
    }
  }, [classifications])

  const isMult = LanguageSwitch()?.isMult

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteCategory(isOpen?.id))
  }

  useEffect(() => {
    dispatch(
      getAllClassifications(
        page,
        query ? query : sort ?? "all",
        limit,
        searchText
      )
    )
    if (isOpen.loading) {
      dispatch(
        getAllClassifications(
          page,
          query ? query : sort ?? "main",
          limit,
          searchText
        )
      )
    }
  }, [dispatch, page, searchText, isOpen.loading])

  useEffect(() => {
    if (query) {
      setSort(query)
    } else {
      setSort("all")
    }
  }, [])

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])



  const columns = [
    {
      dataField: "primaryLang.name",
      text: "Primary Name",
      sort: true,
    },
    {
      dataField: "secondaryLang.name",
      text: "Secondary Name",
      sort: true,
    },
    {
      dataField: "logoImage",
      text: "Logo Image",
      sort: true,
    },
    {
      dataField: "bannerImage",
      text: "Banner Image",
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]
  const subColumns = [
    ...columns.slice(0, 2),
    {
      dataField: "mainCategory.primaryLang.name",
      text: "Category",
      sort: true,
    },
    ,
    ...columns.slice(2),
  ]

  columns[0].text = isMult ? "Primary Name" : "Name"

  const handleLinks = item => {
    // if (sort === "category") return `/category/update/${item?._id}`
    // if (sort === "sub") return `/category/update/${item?._id}`
    // if (sort === "main") return `/category/update/${item?._id}`
    return `/category/update/${item?._id}`
  }

  const allClassificationsData = map(classifications, (item, index) => ({
    ...item,
    logoImage: (
      <>
        <img
          src={
            item?.logoImages && item?.logoImages[0]
              ? item?.logoImages[0]?.url
              : noImage
          }
          width="50px"
        />
      </>
    ),
    bannerImage: (
      <>
        <img
          src={
            item?.bannerImages && item?.bannerImages[0]
              ? item?.bannerImages[0]?.url
              : noImage
          }
          width="50px"
        />
      </>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={handleLinks(item)}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bxs-pencil me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>

        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => handleDelete(item?._id)}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const listChange = sortValue => {
    history.push({
      pathname: "/classifications",
      search: `${sortValue}?p=${1}`,
      state: {
        page: 1,
      },
    })
    setSort(sortValue)
    dispatch(getAllClassifications(page, sortValue, limit))
  }

  const handleColumns = () => {
    if (sort === "sub" || sort === "sub-main") return subColumns

    return columns
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Col className="mb-3 col-12">
                <div
                  className="btn-group mt-2 mt-xl-0"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <Fragment>
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        checked={query === "all" || (sort === "all" && true)}
                        onChange={() => listChange("all")}
                      />
                      <label className="btn btn-success" htmlFor="btnradio1">
                        Category
                      </label>
                    </>

                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        checked={query === "sub" && true}
                        onChange={() => listChange("sub")}
                      />
                      <label className="btn btn-success" htmlFor="btnradio3">
                        Sub Categories
                      </label>
                    </>

                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        checked={query === "main" && true}
                        onChange={() => listChange("main")}
                      />
                      <label className="btn btn-success" htmlFor="btnradio2">
                        Main Heads
                      </label>
                    </>

                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        checked={query === "sub-main" && true}
                        onChange={() => listChange("sub-main")}
                      />
                      <label className="btn btn-success" htmlFor="btnradio4">
                        Sub Main Heads
                      </label>
                    </>
                  </Fragment>
                </div>
              </Col>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? handleColumns()
                      : handleColumns()?.filter(
                        i => i.dataField !== "secondaryLang.name"
                      )
                  }
                  data={allClassificationsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              {/* <SearchBar {...toolkitProps.searchProps} /> */}
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            {sort === "main" || sort === "sub-main" ? (
                              <Link
                                to={{
                                  pathname: "/category/create/new",
                                  state: sort,
                                }}
                              >
                                <Button
                                  type="button"
                                  color="info"
                                  className="btn-rounded  mb-2 me-2"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Create Head
                                </Button>
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/category/create/new",
                                  state: sort,
                                }}
                              >
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Create Category
                                </Button>
                              </Link>
                            )}
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {classifications?.length >= 1 ? (
                            <Row>
                              <p>Total :&nbsp;{classifications?.length}</p>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Classifications !</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Classifications
